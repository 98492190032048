import React from 'react';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import './dateTime.scss';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useLabelStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
});

const useInputStyles = makeStyles({
  input: {
    background: 'rgba(0, 0, 0, 0.04)',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    fontWeight: '400',
    paddingLeft: 5,
    paddingRight: 5,
  },
});

const MaterialDateTime = ({ name, required, label }) => {
  const [field, meta, helpers] = useField(name);
  const { error } = meta;
  const { submitCount } = useFormikContext();
  const labelStyles = useLabelStyles();
  const inputStyles = useInputStyles();

  const hasError = submitCount && error ? true : false;

  const handleChange = value => {
    const test = value.format();
    helpers.setValue(test);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className="datetime-picker">
        <InputLabel
          variant="standard"
          disableAnimation
          shrink={false}
          classes={labelStyles}
          error={hasError}
          required={required}
          htmlFor={field.name}
        >
          {label}
        </InputLabel>
        <DateTimePicker
          {...field}
          fullWidth
          onChange={handleChange}
          InputProps={{ classes: inputStyles }}
        />
        {hasError ? (
          <Box component="span" color="error.main">
            {error}
          </Box>
        ) : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default MaterialDateTime;
