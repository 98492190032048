import SearchableSelect from 'components/SearchableSelect/SearchableSelect';
import React, { useEffect } from 'react';

const SearchableSelectContractorFilter = ({ onChange, ...props }) => {
  const [value, setValue] = React.useState(null);

  const handleChange = (event, option) => {
    setValue(option);
    onChange(event, option?.value);
  };

  /**
   * When the component is mounted, we want to clear the value
   * so that the filter is reset to empty
   */
  useEffect(() => {
    onChange('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-grow-1">
      <div style={{ margin: 8, minWidth: 120, width: '100%' }}>
        <SearchableSelect
          getOptionSelected={(option, value) => option.name === value.name}
          value={value}
          onChange={handleChange}
          {...props}
        />
      </div>
    </div>
  );
};

export default SearchableSelectContractorFilter;
