import { range, map } from 'lodash';
import moment from 'moment';

export const SENIORITY_SELECT_OPTIONS = [
  {
    name: `Lead`,
    value: 'Lead',
  },
  {
    name: `Jr.`,
    value: 'Jr.',
  },
  {
    name: `Mid`,
    value: 'Mid',
  },
  {
    name: `Senior`,
    value: 'Senior',
  },
];

export const PROJECT_SERVICES = {
  Design: 'Design',
  Facilitation: 'Facilitation',
  Training: 'Training',
  Program: 'Program',
  Certification: 'Certification',
  Keynote: 'Keynote',
};

export const PROJECT_SERVICE_SELECT_OPTIONS = map(PROJECT_SERVICES, service => ({
  name: service,
  value: service,
}));

export const STAFF_TYPES = {
  Employee: 'Employee',
  Contractor: 'Contractor',
};

export const STAFFING_TYPE_SELECT_OPTIONS = map(STAFF_TYPES, staffType => ({
  name: staffType,
  value: staffType,
}));

export const JOB_ROLES = {
  Prototyper: 'Prototyper',
  Facilitator: 'Facilitator',
  Scribe: 'Scribe',
  Strategist: 'Strategist',
  Designer: 'Designer',
};

export const JOB_ROLE_SELECT_OPTIONS = map(JOB_ROLES, role => ({ name: role, value: role }));

//Transform Range of 1-5 (stepped by 1) to Select Options
export const SEAT_SELECT_OPTIONS = map(range(1, 16, 1), value => {
  return { name: value.toString(), value };
});

//Get the List of all Months (Start & End Date) of the Current Year
export const SCHEDULES_MONTHS_OPTIONS = map(moment.monthsShort(), (month, index) => {
  const date = moment(`${new Date().getFullYear()}-${index + 1}-01`);

  // get the number of days for this month
  const daysInMonth = date.daysInMonth();

  //Get the Full Month Name
  const fullMonth = date.format('MMMM');

  return {
    start: `${month} 1`,
    end: `${month} ${daysInMonth}`,
    month,
    fullMonth,
    monthIndex: index,
  };
});

export const DEAL_STATUS_SELECT_OPTIONS = [
  {
    name: 'Pending',
    value: 'pending',
  },
  {
    name: 'Contact Sent',
    value: 'contract-sent',
  },
  {
    name: 'Ready to Invoice',
    value: 'ready-to-invoice',
  },
  {
    name: 'Deposit Invoiced',
    value: 'deposit-invoiced',
  },
  {
    name: 'In Progress',
    value: 'in-progress',
  },
  {
    name: 'Completed',
    value: 'completed',
  },
  {
    name: 'Closed',
    value: 'closed',
  },
];

export const PROJECT_LOCATION_TYPES = {
  virtual: 'virtual',
  hybrid: 'hybrid',
  in_person: 'in_person',
};

export const PROJECT_LOCATION_TYPES_SELECT_OPTIONS = [
  {
    name: 'Virtual',
    value: 'virtual',
  },
  {
    name: 'Hybrid',
    value: 'hybrid',
  },
  {
    name: 'In Person',
    value: 'in_person',
  },
];

export const PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS = [
  {
    name: 'Daily',
    value: 'DAILY',
  },
  {
    name: 'Weekly',
    value: 'WEEKLY',
  },
];

export const DEAL_STATUS_PRICE_OPTIONS = [
  {
    name: '$0 - $30,000',
    value: [0, 30000],
  },
  {
    name: '$30,000 - $60,000',
    value: [30000, 60000],
  },
  {
    name: '$60,000 - $90,000',
    value: [60000, 90000],
  },
];

export const PROJECTS_CHECKLISTS_SEEDS = {
  Staffing: [
    {
      title: 'Create jobs',
      leadTime: 35, // leadTime is in days
    },
    {
      title: 'Assign jobs',
      leadTime: 35,
    },
    {
      title: 'Create & assign VC Emails if needed',
      leadTime: 35,
    },
    {
      title: 'Download & file signed staff contracts',
      leadTime: 35,
    },
    {
      title: 'Project Folder shared with contracted staff',
      leadTime: 35,
    },
  ],
  'Asset QA': [
    {
      title: 'All links in Run of Show are correct',
      leadTime: 21,
    },
    {
      title: 'Session Lab cloned & in Clients folder',
      leadTime: 21,
    },
    {
      title: 'Session Lab has correct number of days',
      leadTime: 21,
    },
    {
      title: 'Facilitator & Scribe have edit access to Session Lab',
      leadTime: 21,
    },
    {
      title: 'Facilitator & Scribe are Facilitators on Mural/Miro',
      leadTime: 21,
    },
    {
      title: 'Checklist created and populated correctly',
      leadTime: 21,
    },
    {
      title: 'Contractors added to Calendar Invite',
      leadTime: 21,
    },
  ],
};

export const PROJECT_STATUS_STATES = {
  INITIALIZING: 'initializing',
  PENDING: 'pending',
  ADDING_CALENDAR: 'adding-calendar',
  SCHEDULED: 'scheduled',
  CREATING_ASSETS: 'creating-assets',
  IN_PROGRESS: 'in-progress',
  READY_TO_INVOICE: 'ready-to-invoice',
  INVOICING: 'invoicing',
  CLOSED: 'closed',
};

export const PROJECT_ERROR_STATUS_STATES = {
  INITIALIZING: 'initializing-error',
  CREATING_ASSETS: 'creating-assets-error',
  READY_TO_INVOICE: 'ready-to-invoice-error',
  ADDING_CALENDAR: 'adding-calendar-error',
};

export const DEAL_STATUS_STATES = {
  INITIALIZING: 'initializing',
  PENDING: 'pending',
  CONTRACT_SENT: 'contract-sent',
  READY_TO_INVOICE: 'ready-to-invoice',
  DEPOSIT_INVOICED: 'deposit-invoiced',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  CLOSED: 'closed',
};

export const DEAL_ERROR_STATUS_STATES = {
  INITIALIZING: 'initializing-error',
  READY_TO_INVOICE: 'ready-to-invoice-error',
};

export const VC_HUBSPOT_ACCOUNT_ID = '9003022';

export const PROJECT_ATTENDEE_MESSAGE_DESTINATIONS = Object.freeze({
  Email: 'email',
  CircleDM: 'circle-dm',
  CircleCohort: 'circle-cohort',
});
