import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';
import { InputLabel, Box, Typography } from '@material-ui/core';
import AutoSuggestInput from 'components/AutoSuggestInput';
import { ContactsOutlined } from '@material-ui/icons';

const useLabelStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const AutoCompleteTextInput = ({
  afterChange, // onChange callback
  customOnChange, // override the standard Formik onChange
  customOnInputChange,
  customStyles = { container: undefined, label: undefined },
  disabled,
  freeSolo, // prevent usage of freeSolo
  getOptionLabel, // prevent usage of getOptionLabel
  // TODO getOptionSelected is renamed to isOptionEqualToValue on in future versions
  getOptionSelected, // prevent usage of getOptionSelected
  helperText,
  label,
  name,
  options,
  placeholder,
  required,
  strict = false,
  value, // prevent direct usage of value
  valueOverride,
  ...props
}) => {
  const classes = useStyles();
  const labelsClasses = useLabelStyles();
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const handleChange = (event, value) => {
    if (!strict) return;
    helpers.setValue(value);
    if (afterChange) afterChange(value);
  };

  const handleInputChange = (event, value, reason) => {
    if (strict) return;
    helpers.setValue(value);
    if (afterChange) afterChange(value);
  };

  return (
    <div className={cx('auto-suggest-text-input', customStyles.container || 'w-100')}>
      {label && (
        <Box className="" style={{ marginBottom: '14px' }}>
          <InputLabel
            variant="standard"
            disableAnimation
            shrink={false}
            className={cx(labelsClasses.default, customStyles.label || '')}
            error={hasError}
            required={required}
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          {helperText && <Typography className={classes.helperText}> {helperText}</Typography>}
        </Box>
      )}

      <AutoSuggestInput
        {...field}
        name={field.name}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={field.onBlur}
        onChange={customOnChange ?? handleChange}
        onInputChange={customOnInputChange ?? handleInputChange}
        required={required}
        options={options}
        id={field.name}
        fullWidth
        value={valueOverride || field.value}
        hasError={hasError}
        {...props}
      />
      {hasError ? (
        <Box component="span" color="error.main" style={{ fontWeight: '400' }}>
          {error}
        </Box>
      ) : null}
    </div>
  );
};

export default AutoCompleteTextInput;
