import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { keys } from 'lodash';
// library components
import { Formik, Form } from 'formik';
import { Button, Typography, Box, makeStyles, Divider } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
// cr components
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';
import TextInput from 'components/CustomFormComponents/TextInput';
import ParticipantNameForm from 'components/ParticipantNamePrompt/ParticipantNameForm';
// actions
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';
import {
  openPremiumSubscriptionDialog,
  showAppOverlaySpinner,
} from 'redux/actions/facilitatorSessionActions';
// selectors
import { facilitatorSubscriptionSelector } from 'redux/selectors/facilitatorSessionSelectors';
import Spinner from 'components/Spinner';
// utils
import { changeEmail } from '../../utils/changeEmail';

const useStyles = makeStyles(theme => ({
  label: {
    marginRight: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
  },
  premuimSubscription: {
    color: '#CEB070',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 400,
    '&:hover': {
      background: 'unset',
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
    fontSize: 16,
    marginLeft: 4,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

/**
 * Form used by particicpants to change their display name
 */
const FacilitatorAccountSEttingsForm = ({ closeDialog }) => {
  const classes = useStyles();
  const db = useFirestore();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const isMuralAuthorized = profile.isMuralAuthorized ?? false;
  const { isSubscriber, isInternalUser } = useSelector(facilitatorSubscriptionSelector());
  const firebase = useFirebase();
  const [showAuthMuralButton, setShowAuthMuralButton] = useState(!isMuralAuthorized);
  const [isDisconnectingMural, setIsDisconnectingMural] = useState(false);

  const formSchema = Yup.object().shape({
    displayName: Yup.string().required('Required'),
    email: Yup.string().email().required(),
  });

  useEffect(() => {
    (async () => {
      if (!isMuralAuthorized) {
        const muralTokens = await db.doc(`muralOAuth/${authUser.uid}`).get();

        if (muralTokens.exists) {
          await db.doc(`users/${authUser.uid}`).update({ isMuralAuthorized: true });
          setShowAuthMuralButton(false);
        } else {
          setShowAuthMuralButton(true);
        }
      }
    })();
  }, [isMuralAuthorized]);

  const onUpgradeSubscription = () => {
    dispatch(openPremiumSubscriptionDialog(true));
  };

  const getMuralAuthUrl = () => {
    dispatch(showAppOverlaySpinner(true));

    firebase
      .functions()
      .httpsCallable('mural-getAuthUrl')({ redirectUrl: window.location.href })
      .then(res => {
        window.location.replace(res.data);
      })
      .catch(err => {
        dispatch(showAppOverlaySpinner(false));
        dispatch(enqueueErrorSnackbar('Error getting auth url'));
        console.error('get auth url error', err);
      });
  };

  const refreshMuralWorkspaces = () => {
    dispatch(showAppOverlaySpinner(true));

    firebase
      .functions()
      .httpsCallable('mural-refreshUserWorkspaces')()
      .then(res => {
        dispatch(enqueueSuccessSnackbar('Mural workspaces refreshed'));
      })
      .catch(err => {
        dispatch(enqueueErrorSnackbar('Error refreshing workspaces'));
        console.error(err);
      })
      .finally(() => {
        dispatch(showAppOverlaySpinner(false));
      });
  };

  const disconnectMuralAccount = () => {
    setIsDisconnectingMural(true);

    const batch = db.batch();
    const muralAuthRef = db.doc(`muralOAuth/${authUser.uid}`);
    const userRef = db.doc(`users/${authUser.uid}`);

    batch.delete(muralAuthRef);
    batch.update(userRef, { isMuralAuthorized: false });

    batch
      .commit()
      .then(() => {
        dispatch(enqueueSuccessSnackbar('Mural Account Disconnected'));
        setIsDisconnectingMural(false);
      })
      .catch(error => {
        setIsDisconnectingMural(false);
        dispatch(enqueueErrorSnackbar('Error disconnecting Mural account'));
        console.error(error);
      });
  };

  return (
    <Formik
      initialValues={{
        displayName: authUser.displayName,
        email: authUser.email,
      }}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const currentUser = firebase.auth().currentUser;
        const currentEmail = currentUser.email;
        const update = {};
        const batch = db.batch();

        if (values.displayName !== currentUser.displayName) {
          update.displayName = values.displayName;
        }

        if (keys(update).length) {
          try {
            const userRef = db.doc(`users/${currentUser.uid}`);
            batch.update(userRef, update);

            if (profile.vcContractor && profile.contractorId && update.displayName) {
              const contractorRef = db.doc(`contractors/${profile.contractorId}`);
              batch.update(contractorRef, { displayName: update.displayName });
            }

            await batch.commit();
            await firebase.updateAuth(update, true);
          } catch (err) {
            setSubmitting(false);
            dispatch(enqueueErrorSnackbar('Error updating name'));
            console.error('Error updating settings: ', err);
            return;
          }
        }

        if (values.email !== currentEmail) {
          try {
            await changeEmail(currentUser.uid, values.email);
            // user will have to log back in on reload for new address to take effect
            window.location.reload();
            return;
          } catch (err) {
            dispatch(enqueueErrorSnackbar(err.message));
          }
        }

        setSubmitting(false);
        closeDialog();
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form style={{ paddingBottom: 20 }}>
          <TextInput
            name="displayName"
            type="text"
            label="Display Name"
            className="mb-4"
            placeholder="Enter your first and last name"
            required
          />
          <TextInput
            name="email"
            type="email"
            label="Email"
            className="mb-1"
            required
            // readOnly={true}
          />
          <Typography className="mb-4" style={{ fontSize: 12 }}>
            Note: you will have to log back in again after changing your email
          </Typography>
          {/* <Box className="d-flex align-items-center">
            <Typography className={classes.label}>Subscription</Typography>
            {isSubscriber ?
              <Typography className={classes.premuimSubscription}>Premium Account</Typography>
              :
              <Button
                className={classes.button}
                onClick={onUpgradeSubscription}
                disabled={isDisconnectingMural}
              >
                Upgrade
                {isDisconnectingMural &&
                  <ButtonSpinner />
                }
              </Button>
            }
          </Box> */}
          {isInternalUser && (
            <Box>
              <Box className="my-3">
                <Typography className={classes.label}>User ID: {authUser.uid}</Typography>
              </Box>
            </Box>
          )}
          <Divider />
          {isInternalUser && (
            <>
              {!showAuthMuralButton && !isMuralAuthorized && (
                <Box className="my-3">
                  <Spinner />
                </Box>
              )}
              {showAuthMuralButton && (
                <Box className="my-3">
                  <Typography className={classes.label}>Mural Account: Not Connected</Typography>
                  <Box display="flex" mt={1}>
                    <Button
                      color="primary"
                      elevation={0}
                      variant="contained"
                      onClick={getMuralAuthUrl}
                      type="button"
                      size="small"
                      className="mr-1"
                    >
                      Authorize Mural Account
                    </Button>
                  </Box>
                </Box>
              )}
              {isMuralAuthorized && (
                <Box className="my-3">
                  <Typography className={classes.label}>
                    Mural Account: &nbsp;<span className={classes.success}>Authorized</span>
                    <CheckCircleOutlineOutlinedIcon className={classes.checkIcon} />
                  </Typography>
                  <Box display="flex" mt={1}>
                    <Button
                      color="primary"
                      elevation={0}
                      variant="contained"
                      onClick={disconnectMuralAccount}
                      type="button"
                      size="small"
                      className="mr-1"
                    >
                      Disconnect Mural Account
                    </Button>
                    <Button
                      color="primary"
                      elevation={0}
                      variant="contained"
                      onClick={refreshMuralWorkspaces}
                      type="button"
                      size="small"
                    >
                      Refresh Mural Workspaces
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}

          <div className="form-dialog-buttons">
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              SAVE
              {isSubmitting && <ButtonSpinner />}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ParticipantNameForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default FacilitatorAccountSEttingsForm;
