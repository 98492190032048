import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// core components
import { makeStyles } from '@material-ui/core';
import { Input, InputLabel, FormControl } from '@material-ui/core';
import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';
import SearchableSelectContractorFilter from './SearchableSelectContractorFilter';

// constants
import { JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';
import { STAFFING_TYPE_SELECT_OPTIONS } from 'constants/staffing';
import { SENIORITY_SELECT_OPTIONS } from 'constants/staffing';

//actions
import { setContractorFilterPayload } from 'redux/actions/staffingActions';
import { selectFiltersSelectOptions } from 'redux/selectors/staffing/getContractor';
import { selectCompanies } from 'redux/selectors/staffing/getDeals';
import { selectProjectMethodologies } from 'redux/selectors/staffing/getProject';

// styles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    columnGap: '15px',
    rowGap: '5px',
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: -5,
    flexGrow: 1,
  },
  input: {
    backgroundColor: '#EEEEEE',
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
}));

const ContractorFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getFilters = selectFiltersSelectOptions();
  const { cities, countries, tags, timezones } = useSelector(state => getFilters(state));

  const getAllCompanies = selectCompanies();
  const { companies } = useSelector(state => getAllCompanies(state));

  const getAllProjectMethodologies = selectProjectMethodologies();
  const { projectMethodologies } = useSelector(state => getAllProjectMethodologies(state));

  const handleSelect = (value, filterParam) => {
    dispatch(
      setContractorFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  return (
    <div className={cx(classes.main)}>
      <PickerSelect
        classNames="flex-grow-1"
        label="Primary Role"
        options={JOB_ROLE_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'roleFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        label="Secondary Role"
        options={JOB_ROLE_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'secondaryRoleFilter');
        }}
      />

      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="maxRateInput"
          className={classes.inputLabel}
          classes={{ shrink: 'pl-0' }}
        >
          Max Rate
        </InputLabel>
        <Input
          id="maxRateInput"
          classes={{
            root: classes.input,
          }}
          placeholder="Max Rate"
          type="number"
          onChange={event => {
            handleSelect(event.currentTarget.value, 'maxRateFilter');
          }}
        />
      </FormControl>

      <SearchableSelectContractorFilter
        options={cities}
        label="City"
        onChange={(event, option) => {
          handleSelect(option, 'cityFilter');
        }}
      />

      <SearchableSelectContractorFilter
        options={countries}
        label="State/Country"
        onChange={(event, option) => {
          handleSelect(option, 'countryFilter');
        }}
      />

      <SearchableSelectContractorFilter
        options={timezones}
        label="Time Zone"
        onChange={(event, option) => {
          handleSelect(option, 'timeZoneFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        label="Seniority"
        options={SENIORITY_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'seniorityFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        label="Staff Type"
        options={STAFFING_TYPE_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'staffingTypeFilter');
        }}
      />

      <SearchableSelectContractorFilter
        options={companies}
        label="Has worked with"
        onChange={(event, option) => {
          handleSelect(option, 'companiesWorkedWithFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        label="Has worked on"
        options={projectMethodologies}
        onChange={value => {
          handleSelect(value, 'methodologiesWorkedWithFilter');
        }}
      />

      <SearchableSelectContractorFilter
        options={tags}
        label="Tags"
        onChange={(event, option) => {
          handleSelect(option, 'tagsFilter');
        }}
      />
    </div>
  );
};

export default ContractorFilter;
