import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import OpenJobList from 'views/Admin/Staffing/ContractorDashboard/OpenJobList';

const ContractorDashboard = () => {
  const user = useSelector(state => state.firebase.profile);

  useFirestoreConnect(() => [
    {
      collection: 'contractors',
      where: [['email', '==', user.email]],
      storeAs: 'currentContractor',
    },
    {
      collection: 'projects',
      storeAs: 'projects',
    },
    {
      collection: 'deals',
      storeAs: 'deals',
    },
    {
      collection: 'companies',
      storeAs: 'companies',
    },
  ]);

  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/contractor/"
          render={routeProps => <OpenJobList {...routeProps} />}
        />
      </Switch>
    </>
  );
};

export default ContractorDashboard;
