import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// core components
import { Button, Grid } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LaunchIcon from '@material-ui/icons/Launch';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FolderIcon from '@material-ui/icons/Folder';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/styles';
import { useConfirm } from 'material-ui-confirm';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { selectDeal } from 'redux/selectors/staffing/getDeals';
import {
  openAddProjectToDealDialog,
  openCreateDealDialog,
  updateDeal,
  deleteDeal,
} from 'redux/actions/staffingActions';

import { includes, isEmpty, map, filter } from 'lodash';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import UploadSignedContractDialog from 'components/Dialogs/UploadSignedContractDialog';
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';
import DealStatusView from 'components/Staffing/Deals/DealCard/DealStatusView';
import AddDealDialog from 'components/Dialogs/Staffing/AddDealDialog';
import AddProjectToDealDialog from 'components/Dialogs/Staffing/AddProjectToDealDialog';
import ProjectCard from 'components/Staffing/Projects/ProjectCard/index.js';
import {
  DEAL_STATUS_STATES,
  DEAL_ERROR_STATUS_STATES,
  VC_HUBSPOT_ACCOUNT_ID,
  PROJECT_STATUS_STATES,
} from 'constants/staffing';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'redux/actions/notifierActions';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #E5E5E5',
  },

  // Header
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    padding: '15px 15px',
  },
  header_left_right: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexBasis: 0,
  },

  // Menu button
  menuContainer: {
    position: 'relative',
  },
  menuButton: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#DBDBDB',
    marginLeft: 7,
  },

  // Detail Card
  bodyView: {
    width: '100%',
    padding: '20px 30px',
  },
  dealDetailCard: {
    minHeight: 700,
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E3E3E3',
    borderRadius: 4,
    padding: 20,
  },
  dealDetailCardHeader: {
    borderBottom: '1px solid #E1E1E1',
  },

  // Info Label & Description Text
  infoLabelContainer: {
    minHeight: 20,
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 11,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  infoTitleLG: {
    fontSize: 24,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '30px',
  },
  infoDateLabel: {
    fontSize: 18,
  },
  infoTitleMD: {
    fontSize: 18,
    fontWeight: '400',
    color: '#333333',
  },
  dealDescriptionText: {
    fontSize: 15,
    fontWeight: '300',
    lineHeight: '21px',
    marginBottom: 0,
  },

  // Deal Contact Button
  dealContactBtn: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#EBEBEB',
    border: '1px solid #D7D5D5',
    borderRadius: 99,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dealContactBtnText: {
    fontSize: 14,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginLeft: 5,
  },

  linkBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  linkBtnText: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginLeft: 5,
    marginBottom: 0,
  },

  // Projects section
  projectsSection: {
    width: '100%',
    padding: '10px 0px',
  },

  noProjectsView: {
    padding: '50px 0px',
  },
  noProjectsText: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#898989',
    textAlign: 'center',
  },

  // Details
  moreDetailsBtn: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#D8E1E7',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    marginRight: 5,
    marginBottom: 10,
    cursor: 'pointer',
  },
  moreDetailsBtnDisabled: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#EBEBEB',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 99,
    marginRight: 5,
    marginBottom: 10,
  },

  moreDetailsBtnText: {
    fontSize: 13,
    fontWeight: '500',
    color: theme.palette.primary.main,
    marginBottom: 0,
  },
  moreDetailsBtnTextDisabled: {
    fontSize: 13,
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 0,
  },
  moreDetailsBtnIcon: {
    fontSize: 15,
    marginRight: 5,
  },

  checklistsSection: {
    borderBottom: '1px solid #E1E1E1',
    padding: '10px 0px',
  },

  // Jobs Section
  jobsSection: {
    width: '100%',
    padding: '10px 0px',
    margin: '10px 0px',
  },
}));

const DealDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { dealId } = useParams();

  const getDeal = selectDeal();
  const deal = useSelector(state => getDeal(state, dealId));
  const confirm = useConfirm();
  const history = useHistory();
  const [contractUploadDialogIsOpen, setContractUploadDialogIsOpen] = useState(false);
  const [contractUploadActionType, setContractUploadActionType] = useState('');

  const toggleContractUploadDialog = () => {
    setContractUploadDialogIsOpen(!contractUploadDialogIsOpen);
  };

  const getMenuOptions = () => {
    const options = [
      {
        title: 'Edit Deal',
        onItemClick: () => {
          dispatch(openCreateDealDialog(true, dealId));
        },
      },
      {
        title: 'Delete Deal',
        onItemClick: () => {
          confirm({
            description: 'The Deal, SOW, and Services Agreement will all be deleted. Are you sure?',
          }).then(() => {
            if (deal.projects.length) {
              dispatch(
                enqueueErrorSnackbar(
                  'A deal cannot be deleted while it has associated projects. Delete the projects first and try again.',
                ),
              );
            } else {
              dispatch(
                deleteDeal(deal.id, () => {
                  history.push('/admin/staffing/deals');
                  dispatch(enqueueSuccessSnackbar('Deal deleted'));
                }),
              );
            }
          });
        },
      },
    ];

    if (deal.status !== DEAL_STATUS_STATES.CLOSED) {
      options.push({
        title: 'Add Project',
        onItemClick: () => {
          dispatch(openAddProjectToDealDialog(true, dealId));
        },
      });
    }

    if (
      deal.status === DEAL_STATUS_STATES.READY_TO_INVOICE ||
      deal.status === DEAL_STATUS_STATES.DEPOSIT_INVOICED ||
      deal.status === DEAL_STATUS_STATES.IN_PROGRESS ||
      deal.status === DEAL_STATUS_STATES.COMPLETED ||
      deal.status === DEAL_STATUS_STATES.CLOSED
    ) {
      options.push({
        title: Boolean(deal.signedContract?.id)
          ? 'Replace Signed Contract'
          : 'Upload Signed Contract',
        onItemClick: () => {
          setContractUploadActionType(Boolean(deal.signedContract?.id) ? 'replace' : 'create');
          toggleContractUploadDialog();
        },
        disabled: !deal.hubspotDealId,
      });
    }

    // currentStatusIndex >= Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.)
    //   ? {
    //     title: 'Upload Signed Contract',
    //     onItemClick: () => toggleContractUploadDialog(),
    //     // disabled: project.isCreatingAttendeeAssets,
    //   }
    //   : null,

    return options;
  };

  //TODO: WTF is this voodoo?
  // Page loads multiple times and even with the right id doesn't pull the deal?
  if (!deal) {
    return null;
  }

  const contactEmailLink = `mailto:${deal.contactEmail}`;

  return (
    <div className={cx(classes.main)}>
      {/* Header */}
      <div className={cx(classes.header)}>
        <div className={classes.header_left_right}>
          <Button
            variant="text"
            className={'d-flex flex-start'}
            color="primary"
            component={Link}
            to="/admin/staffing/deals"
          >
            <ArrowBackIosIcon fontSize="small" />
            <p className="m-0 text-uppercase">Back to deals</p>
          </Button>
        </div>
        <h4 className="font-weight-bold">{deal.dealName}</h4>

        <div className={classes.header_left_right}>
          <DealStatusButton
            deal={deal}
            toggleContractUploadDialog={toggleContractUploadDialog}
            setContractUploadActionType={setContractUploadActionType}
          />
          <SelectMenu
            menuContainerClassName={classes.menuContainer}
            menuBtnClassName={classes.menuButton}
            options={getMenuOptions()}
          />
        </div>
      </div>

      <div className={classes.bodyView}>
        {/* Deal Details  */}
        <div className={classes.dealDetailCard}>
          <div className={classes.dealDetailCardHeader}>
            <Grid container spacing={5} className="mb-1">
              <Grid item xs={12} sm={6}>
                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Deal Name</p>
                </div>
                <p className={classes.infoTitleLG}>{deal.dealName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Company Name</p>
                </div>
                <p className={classes.infoTitleLG}>{deal.company.companyName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Dates</p>
                </div>
                <p className={cx(classes.infoTitleLG, classes.infoDateLabel)}>
                  {' '}
                  {deal.formattedStartDate} - {deal.formattedEndDate}
                </p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Deal Status</p>
                    </div>
                    <DealStatusView
                      status={deal.status}
                      statusError={deal.statusError}
                      time={deal.timeSpentInCurrentStatus}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Deal Value</p>
                    </div>
                    <p className={classes.infoTitleLG}>{deal.formattedAmount}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Deal Contact</p>
                    </div>

                    <div className={classes.dealContactBtn}>
                      <EmailIcon fontSize="small" color="primary" />
                      <a href={contactEmailLink} target="_blank">
                        <p className={classes.dealContactBtnText}>{deal.contactName}</p>
                      </a>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className="mt-3">
                  {deal.hubspotDealId && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={`https://app.hubspot.com/contacts/${VC_HUBSPOT_ACCOUNT_ID}/deal/${deal.hubspotDealId}/`}
                        className={cx('mb-2', classes.linkBtn)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LaunchIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>Hubspot</p>
                      </a>
                    </Grid>
                  )}

                  {/* {deal.clientHandoffDoc?.webViewLink &&
                    <Grid item xs={4} sm={4} lg={3}>
                        <a
                        href={deal.clientHandoffDoc.webViewLink}
                        className={classes.linkBtn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AssignmentIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>Client hand-off</p>
                      </a>
                    </Grid>
                  } */}

                  {deal.dealFolder?.webViewLink && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={deal.dealFolder.webViewLink}
                        className={cx('mb-2', classes.linkBtn)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FolderIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>Deal Folder</p>
                      </a>
                    </Grid>
                  )}

                  {deal.company?.clientProjectsFolder?.webViewLink && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={deal.company.clientProjectsFolder?.webViewLink}
                        className={cx('mb-2', classes.linkBtn)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FolderIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>
                          {deal.company?.clientProjectsFolder?.name || 'Client Folder'}
                        </p>
                      </a>
                    </Grid>
                  )}

                  {deal.company?.clientPLFolder?.webViewLink && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={deal.company.clientPLFolder?.webViewLink}
                        className={classes.linkBtn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ReceiptIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>P&Ls</p>
                      </a>
                    </Grid>
                  )}

                  {deal.proposalDoc?.webViewLink && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={deal.proposalDoc.webViewLink}
                        className={cx('mb-2', classes.linkBtn)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LocalOfferIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>Proposal</p>
                      </a>
                    </Grid>
                  )}

                  {deal.signedContract?.webViewLink && (
                    <Grid item xs={4} sm={4} lg={3}>
                      <a
                        href={deal.signedContract.webViewLink}
                        className={cx('mb-2', classes.linkBtn)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DescriptionIcon fontSize="small" color="primary" />
                        <p className={classes.linkBtnText}>Signed Contract</p>
                      </a>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mb-1">
              <Grid item xs={12} md={6}>
                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Deal Notes</p>
                </div>

                <p className={classes.dealDescriptionText}>{deal.notes}</p>
              </Grid>
            </Grid>
          </div>

          {/* Deal Projects */}
          <div className={classes.projectsSection}>
            <div className="d-flex align-items-center justify-content-between">
              <div className={classes.infoLabelContainer}>
                <p className={classes.infoLabel}>Projects</p>
              </div>

              <div
                onClick={() => {
                  dispatch(openAddProjectToDealDialog(true, dealId));
                }}
                className={classes.linkBtn}
              >
                <AddCircleIcon fontSize="small" color="primary" />
                <p className={classes.linkBtnText}>Add Project</p>
              </div>
            </div>
            {isEmpty(deal.projects) && (
              <>
                <div className={cx(classes.noProjectsView, 'd-flex justify-content-center')}>
                  <p className={classes.noProjectsText}>
                    No projects yet. Once the deposit has been <br /> received you can start adding
                    projects.
                  </p>
                </div>
              </>
            )}

            <GridContainer className="w-100 m-0">
              {map(deal.projects, (project, idx) => {
                if (project == null) {
                  return null;
                }
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    key={`project-card-${idx}`}
                    style={{ marginBottom: 30 }}
                    className={classes.gridItem}
                  >
                    <ProjectCard id={project.id} />
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
        </div>
      </div>
      <UploadSignedContractDialog
        isOpen={contractUploadDialogIsOpen}
        handleClose={toggleContractUploadDialog}
        closeDialog={toggleContractUploadDialog}
        dealId={dealId}
        actionType={contractUploadActionType}
      />
      <AddDealDialog />
      <AddProjectToDealDialog />
    </div>
  );
};

const DealStatusButton = props => {
  const {
    deal: { status, id, projects, amountLeft, hubspotDealId, signedContract },
  } = props;
  const { toggleContractUploadDialog, setContractUploadActionType } = props;
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const dealCompleted =
    filter(projects, { status: PROJECT_STATUS_STATES.CLOSED }).length === projects.length &&
    amountLeft === 0;

  const getStatusText = () => {
    switch (status) {
      case DEAL_ERROR_STATUS_STATES.INITIALIZING:
        return 'Initialize';
      case DEAL_STATUS_STATES.PENDING:
        return 'Contract Sent';
      case DEAL_STATUS_STATES.CONTRACT_SENT:
      case DEAL_ERROR_STATUS_STATES.READY_TO_INVOICE:
        return 'Contract Signed';
      case DEAL_STATUS_STATES.READY_TO_INVOICE:
        return 'Deposit Invoiced';
      case DEAL_STATUS_STATES.DEPOSIT_INVOICED:
        return 'Deposit Received';
      case DEAL_STATUS_STATES.IN_PROGRESS:
        return 'Completed';
      case DEAL_STATUS_STATES.COMPLETED:
        return 'Close';
      case DEAL_STATUS_STATES.CLOSED:
      default:
        return 'Closed';
    }
  };

  const handleDealStatus = () => {
    //if the Current Deal state is in 'Error', display a confirmation..
    if (includes(Object.values(DEAL_ERROR_STATUS_STATES), status)) {
      confirm({
        description:
          'You are attempting to re-run this step after an error. Please confirm the error has been corrected.',
      }).then(() => {
        //Update the Deal to the equivalent Deal Status state.
        const key = Object.keys(DEAL_ERROR_STATUS_STATES).find(
          key => DEAL_ERROR_STATUS_STATES[key] === status,
        ); //get the Key, e.g 'initializing-error' equals 'INITIALIZING'
        dispatch(updateDeal(id, { status: DEAL_STATUS_STATES[key] }));
      });
      return;
    }

    if (status === DEAL_STATUS_STATES.PENDING) {
      // Button: Contract Sent
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.CONTRACT_SENT }));
    } else if (status === DEAL_STATUS_STATES.CONTRACT_SENT) {
      // Button: Contract Signed
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.READY_TO_INVOICE }));
      if (hubspotDealId && !signedContract) {
        setContractUploadActionType('create');
        toggleContractUploadDialog();
      }
    } else if (status === DEAL_STATUS_STATES.READY_TO_INVOICE) {
      // Button: Deposit Invoiced
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.DEPOSIT_INVOICED }));
    } else if (status === DEAL_STATUS_STATES.DEPOSIT_INVOICED) {
      // Button: Deposit Received
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.IN_PROGRESS }));
    } else if (status === DEAL_STATUS_STATES.IN_PROGRESS) {
      // Button: Completed
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.COMPLETED }));
    } else if (status === DEAL_STATUS_STATES.COMPLETED) {
      // Button: Close
      dispatch(updateDeal(id, { status: DEAL_STATUS_STATES.CLOSED }));
    }
  };

  //hide the button if the Deal is in the below status
  if (includes([DEAL_STATUS_STATES.INITIALIZING], status)) {
    return <div className="flex-1"></div>;
  }

  return (status === DEAL_STATUS_STATES.IN_PROGRESS && !dealCompleted) ||
    status === DEAL_STATUS_STATES.CLOSED ? (
    <div className="ml-auto" />
  ) : (
    <Button
      variant="contained"
      color="primary"
      className="text-uppercase px-4 py-1 ml-auto"
      size="small"
      onClick={handleDealStatus}
    >
      <p className="mb-0 text-uppercase">{getStatusText()}</p>
    </Button>
  );
};

DealStatusButton.propTypes = {
  status: PropTypes.string,
};

export default DealDetails;
