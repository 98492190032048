import React from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';

//selectors
import { selectSchedules } from 'redux/selectors/staffing/getSchedules';

const SchedulesChart = () => {
  const getSchedules = selectSchedules();
  const { schedules, currentMonth } = useSelector(state => getSchedules(state));

  if (isEmpty(schedules))
    return (
      <div className="py-5 d-flex align-items-center justify-content-center">
        <p>No schedules were found for {currentMonth?.fullMonth}.</p>
      </div>
    );

  return (
    <Chart
      width={'100%'}
      height={'300px'}
      chartType="Timeline"
      loader={<div>Loading Schedules</div>}
      data={[
        [
          { type: 'string', id: 'Contractor' },
          { type: 'string', id: 'Project' },
          { type: 'date', id: 'Start' },
          { type: 'date', id: 'End' },
        ],
        ...schedules,
      ]}
      options={{
        timeline: {
          colorByRowLabel: true,
        },
      }}
    />
  );
};

export default SchedulesChart;
