import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// core components
import { makeStyles } from '@material-ui/core';

import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';

import { Input, InputLabel, FormControl } from '@material-ui/core';

import { JOB_ROLE_SELECT_OPTIONS } from 'constants/staffing';
import { SENIORITY_SELECT_OPTIONS } from 'constants/staffing';

//actions
import { setContractorFilterPayload } from 'redux/actions/staffingActions';
import { selectFiltersSelectOptions } from 'redux/selectors/staffing/getContractor';

import { STAFFING_TYPE_SELECT_OPTIONS } from 'constants/staffing';

// useStyles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    columnGap: '15px',
    rowGap: '5px',
  },
  selectFormControl: {
    minWidth: 100,
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: -5,
    flexGrow: 1,
  },
  input: {
    backgroundColor: '#EEEEEE',
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
}));

const SchedulesFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getFilters = selectFiltersSelectOptions();
  const { cities, countries, timezones } = useSelector(state => getFilters(state));

  const handleSelect = (value, filterParam) => {
    dispatch(
      setContractorFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  return (
    <div className={cx(classes.main)}>
      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="Role"
        options={JOB_ROLE_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'roleFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="Seniority"
        options={SENIORITY_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'seniorityFilter');
        }}
      />

      <FormControl className={classes.formControl}>
        <InputLabel
          htmlFor="maxRateInput"
          className={classes.inputLabel}
          classes={{ shrink: 'pl-0' }}
        >
          Max Rate
        </InputLabel>
        <Input
          id="maxRateInput"
          classes={{
            root: classes.input,
          }}
          placeholder="Max Rate"
          type="number"
          onChange={event => {
            handleSelect(event.currentTarget.value, 'maxRateFilter');
          }}
        />
      </FormControl>

      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="City"
        options={cities}
        onChange={value => {
          handleSelect(value, 'cityFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="Country"
        options={countries}
        onChange={value => {
          handleSelect(value, 'countryFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="Time Zone"
        options={timezones}
        onChange={value => {
          handleSelect(value, 'timeZoneFilter');
        }}
      />

      <PickerSelect
        classNames="flex-grow-1"
        formControlClassName={classes.selectFormControl}
        label="All Staff"
        options={STAFFING_TYPE_SELECT_OPTIONS}
        onChange={value => {
          handleSelect(value, 'staffingTypeFilter');
        }}
      />
    </div>
  );
};

export default SchedulesFilter;
