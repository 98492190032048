import React, { useState } from 'react';
import cx from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import copy from 'copy-to-clipboard';

// core components
import { Button, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EmailIcon from '@material-ui/icons/Email';
import FolderIcon from '@material-ui/icons/Folder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import VideocamIcon from '@material-ui/icons/Videocam';
import LinkIcon from '@material-ui/icons/Link';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/Code';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import PillButton from 'components/PillButton';

import { useDispatch, useSelector } from 'react-redux';
import { selectProject } from 'redux/selectors/staffing/getProject';
import {
  openAddJobToDealProjectDialog,
  openAddProjectToDealDialog,
  updateProject,
  reSyncCalendarEntry,
  reSyncAttendeesListFromSheet,
  reSyncAttendeeMessagingConfig,
  overrideDefaultAttendeeMessagingConfig,
} from 'redux/actions/staffingActions';
import {
  openInfoDialog,
  setInfoDialogContent,
  showAppOverlaySpinner,
} from 'redux/actions/facilitatorSessionActions';

import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';
import ProjectStatusView from 'components/Staffing/Projects/ProjectDetailCard/ProjectStatusView';
import ChecklistsSection from 'components/Staffing/Deals/Checklists';
import StaffingChecklistDialog from 'components/Dialogs/Staffing/StaffingChecklistDialog';
import ManageContractorsDialog from 'components/Dialogs/Staffing/ManageContractorsDialog';
import AddProjectToDealDialog from 'components/Dialogs/Staffing/AddProjectToDealDialog';
import JobSeatCard from 'components/Staffing/Projects/ProjectDetailCard/JobSeatCard/';
import AddJobToDealProjectDialog from 'components/Dialogs/Staffing/AddJobToDealProjectDialog';

import { isEmpty, map, includes, compact, orderBy, get } from 'lodash';
import {
  PROJECT_STATUS_STATES,
  PROJECT_ERROR_STATUS_STATES,
  PROJECT_SERVICES,
  JOB_ROLES,
} from 'constants/staffing';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'redux/actions/notifierActions';
import SendAttendeeMessageDialog from 'components/Dialogs/SendAttendeeMessageDialog';
import SendCohortMessageDialog from 'components/Dialogs/SendCohrtMessageDialog';
import { useFirestore } from 'react-redux-firebase';

const CREATE_JOB_PROJECT_STATUS_STATES = [
  PROJECT_STATUS_STATES.PENDING,
  PROJECT_STATUS_STATES.SCHEDULED,
  PROJECT_STATUS_STATES.IN_PROGRESS,
];

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #E5E5E5',
  },

  // Header
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid lightgrey',
    padding: '15px 15px',
  },
  header_left_right: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: 0,
  },

  // Menu button
  menuContainer: {
    position: 'relative',
  },
  menuButton: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: '#DBDBDB',
    marginLeft: 7,
  },

  // Detail Card
  bodyView: {
    width: '100%',
    padding: '20px 30px',
  },
  dealDetailCard: {
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)',
    border: '1px solid #E3E3E3',
    borderRadius: 4,
    padding: 20,
  },
  dealDetailCardHeader: {
    borderBottom: '1px solid #E1E1E1',
  },

  // Info Label & Description Text
  infoLabelContainer: {
    minHeight: 20,
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 11,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'uppercase',
  },
  infoTitleLG: {
    fontSize: 24,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '30px',
  },
  infoTitleMD: {
    fontSize: 18,
    fontWeight: '400',
    color: '#333333',
  },
  dealDescriptionText: {
    fontSize: 15,
    fontWeight: '300',
    lineHeight: '21px',
    marginBottom: 0,
  },

  // Deal Contact Button
  dealContactBtn: {
    display: 'inline-flex',
    padding: '3px 10px',
    backgroundColor: '#EBEBEB',
    border: '1px solid #D7D5D5',
    borderRadius: 99,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dealContactBtnText: {
    fontSize: 14,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginLeft: 5,
  },

  linkBtn: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  linkBtnText: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.palette.primary.main,
    marginLeft: 5,
    marginBottom: 0,
  },

  // Projects section
  projectsSection: {
    width: '100%',
    padding: '10px 0px',
  },

  noProjectsView: {
    padding: '50px 0px',
  },
  noProjectsText: {
    fontSize: '15px',
    fontWeight: '400',
    color: '#898989',
    textAlign: 'center',
  },

  // Details
  checklistsSection: {
    borderBottom: '1px solid #E1E1E1',
    padding: '10px 0px',
  },

  // Jobs Section
  jobsSection: {
    width: '100%',
    padding: '10px 0px',
    margin: '10px 0px',
  },
  dealStatusButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  dealStatusError: {
    alignSelf: 'end',
    fontSize: 12,
  },
}));

const ProjectDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();
  const db = useFirestore();
  const { projectId } = useParams();
  const getProject = selectProject();
  const project = useSelector(state => getProject(state, projectId));
  const [attendeeMessageDialogIsOpen, setAttendeeMessageDialogIsOpen] = useState(false);
  const [cohortMessageDialogIsOpen, setCohortMessageDialogIsOpen] = useState(false);

  if (!project) return null;

  const isDesign = project.serviceType === PROJECT_SERVICES.Design;
  const isProgram = project.serviceType === PROJECT_SERVICES.Program;
  const isCertification = project.serviceType === PROJECT_SERVICES.Certification;
  const currentStatusIndex = Object.values(PROJECT_STATUS_STATES).indexOf(project.status);

  const contactEmail = `${project.contactEmail || project.dealContactEmail}`;
  const contactEmailLink = `mailto:${contactEmail}`;
  const contactName = `${project.contactName || project.dealContactName}`;

  const toggleAttendeeMessageDialog = () =>
    setAttendeeMessageDialogIsOpen(!attendeeMessageDialogIsOpen);
  const toggleCohortMessageDialog = () => setCohortMessageDialogIsOpen(!cohortMessageDialogIsOpen);

  const showAddJob = includes(CREATE_JOB_PROJECT_STATUS_STATES, project.status);

  const reSyncCalendar = () => {
    confirm({
      description:
        'This will re-sync the google calendar entry with the data from this project and notify all attendees on the calendar event.',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncCalendarEntry(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const reSyncAttendeesList = () => {
    confirm({
      description:
        'This will sync the Attendees List with the project and add attendees to the calendar event. It will also create attendee assets, add attendees to Mural and Circle where applicable, and enabled the scheduled attendee messaging. After the first time a sync is run it will also run nightly until the project start date.',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncAttendeesListFromSheet(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const reSyncMessagingConfig = () => {
    confirm({
      description:
        'This will sync the Attendee Messaging Config for the project from the messaging config google sheet according to the project settings',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        reSyncAttendeeMessagingConfig(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const overrideDefaultMessagingConfig = () => {
    confirm({
      description:
        'This will override the default messaging config by copying the default config into the project folder. The copied config will be used for the project moving forward instead of the default config. Previously sent messages will not be re-sent as long as they have the same ID.',
    }).then(async () => {
      dispatch(showAppOverlaySpinner(true));

      dispatch(
        overrideDefaultAttendeeMessagingConfig(
          projectId,
          () => {
            dispatch(showAppOverlaySpinner(false));
            dispatch(enqueueSuccessSnackbar());
          },
          () => {
            dispatch(showAppOverlaySpinner(false));
          },
        ),
      );
    });
  };

  const copyAttendeesJson = async () => {
    const _attendees = await (await db.collection('projectAttendees').doc(projectId).get()).data()
      ?.attendees;

    if (!_attendees) {
      dispatch(enqueueErrorSnackbar('No attendee data found'));
      return;
    }

    copy(JSON.stringify(_attendees, null, 2), {
      onCopy: () => {
        dispatch(enqueueSuccessSnackbar('Attendees Data Copied'));
      },
      format: 'text/plain',
    });
  };

  const menuOptions = compact([
    {
      title: 'Edit Project',
      onItemClick: () => {
        dispatch(openAddProjectToDealDialog(true, project.dealId, project.id));
      },
      disabled: project.isCreatingAttendeeAssets,
    },
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.SCHEDULED) &&
    !isProgram &&
    !isDesign
      ? {
          title: 'Re-sync Calendar Event Info',
          onItemClick: () => reSyncCalendar(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    !isProgram &&
    !isDesign
      ? {
          title: project.hasSyncedAttendees ? 'Re-sync Attendees' : 'Sync Attendees',
          onItemClick: () => reSyncAttendeesList(),
          disabled: project.isCreatingAttendeeAssets,
          loading: project.isCreatingAttendeeAssets,
        }
      : null,
    !isProgram && !isDesign && !project.customMessagingConfigSheet?.id
      ? {
          title: 'Override Default Messaging Config',
          onItemClick: () => overrideDefaultMessagingConfig(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    !isProgram &&
    !isDesign
      ? {
          title: 'Re-Sync Messaging Config',
          onItemClick: () => reSyncMessagingConfig(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    project.hasSyncedAttendees
      ? {
          title: 'Copy Attendees Data',
          onItemClick: () => copyAttendeesJson(),
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    project.hasSyncedAttendees
      ? {
          title: 'Manual Attendee Message',
          onItemClick: () => toggleAttendeeMessageDialog(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    currentStatusIndex >=
      Object.values(PROJECT_STATUS_STATES).indexOf(PROJECT_STATUS_STATES.IN_PROGRESS) &&
    !isProgram &&
    !isDesign &&
    isCertification &&
    project.hasSyncedAttendees
      ? {
          title: 'Manual Cohort Message',
          onItemClick: () => toggleCohortMessageDialog(),
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    //hide the Previous Stage button when the Project is pending/closed
    !includes(
      [
        PROJECT_STATUS_STATES.PENDING,
        PROJECT_STATUS_STATES.CLOSED,
        PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS,
      ],
      project?.status,
    )
      ? {
          title: 'Previous Stage',
          onItemClick: () => {
            dispatch(
              updateProject(project.id, {
                status: Object.values(PROJECT_STATUS_STATES)[currentStatusIndex - 1],
              }),
            );
          },
          disabled: project.isCreatingAttendeeAssets,
        }
      : null,
    // {
    //   title: "Close",
    //   onItemClick: () => {
    //     dispatch(updateProject(project.id, { status: PROJECT_STATUS_STATES.CLOSED }));
    //   },
    // },
  ]);

  if (!project) {
    return null;
  }

  return (
    <div className={cx(classes.main)}>
      {/* Header */}
      <div className={cx(classes.header)}>
        <div className={classes.header_left_right}>
          <Button
            variant="text"
            className={'d-flex flex-start'}
            color="primary"
            component={Link}
            to={`/admin/staffing/deals/${project.dealId}`}
          >
            <ArrowBackIosIcon fontSize="small" />
            <p className="m-0 text-uppercase">Back to {project.dealName}</p>
          </Button>
        </div>
        <h4 className="font-weight-bold">{project.eventName}</h4>

        <div className={classes.header_left_right}>
          <ProjectStatusButton project={project} />
          <SelectMenu
            menuContainerClassName={classes.menuContainer}
            menuBtnClassName={classes.menuButton}
            options={menuOptions}
          />
        </div>
      </div>

      <div className={classes.bodyView}>
        {/* Deal Details  */}
        <div className={classes.dealDetailCard}>
          <div className={classes.dealDetailCardHeader}>
            <Grid container spacing={5} className="mb-1">
              <Grid item xs={12} sm={6} md={6}>
                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Project Name</p>
                </div>
                <p className={classes.infoTitleLG}>{project.eventName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Deal Name</p>
                </div>
                <p className={classes.infoTitleLG}>{project.dealName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Company Name</p>
                </div>
                <p className={classes.infoTitleLG}>{project.clientName}</p>

                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>About</p>
                </div>

                <p className={classes.dealDescriptionText}>{project.notes}</p>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Project Stage</p>
                    </div>
                    <ProjectStatusView status={project.status} statusError={project.statusError} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Amount</p>
                    </div>
                    <p className={classes.infoTitleLG}>{project.formattedProjectContractValue}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>Project Contact</p>
                    </div>

                    <div className={classes.dealContactBtn}>
                      <EmailIcon fontSize="small" color="primary" />
                      <a href={contactEmailLink} target="_blank">
                        <p className={classes.dealContactBtnText}>{contactName}</p>
                      </a>
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={12}>
                    <div className={classes.infoLabelContainer}>
                      <p className={classes.infoLabel}>More Details</p>
                    </div>

                    <div className="d-flex align-items-center flex-wrap">
                      <PillButton
                        type="plainText"
                        text={project.isDealPublic ? 'Public' : 'Private'}
                        disabled
                      />
                      {project.isCreatingAttendeeAssets && !project.errorCreatingAttendeeAssets && (
                        <PillButton
                          type="plainText"
                          text="Creating Attendee Assets"
                          disabled
                          loading
                        />
                      )}
                      {project.errorCreatingAttendeeAssets && (
                        <PillButton
                          type="plainText"
                          text="Creating Attendee Assets"
                          disabled
                          hasError
                          tooltipText={`Error: ${project.errorCreatingAttendeeAssets}`}
                        />
                      )}
                      {project.isFree && <PillButton type="plainText" text="Free" disabled />}
                      {project.venue && (
                        <PillButton type="plainText" text={project.venue} Icon={LocationOnIcon} />
                      )}
                      {['hybrid', 'virtual'].includes(project.locationType) && project.zoomLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.zoomLink}
                          text="Zoom Link"
                          Icon={VideocamIcon}
                        />
                      )}
                      {project.projectBrief?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.projectBrief.webViewLink}
                          text="Project Brief"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.checklistDoc?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.checklistDoc.webViewLink}
                          text="Checklist"
                          Icon={LinkIcon}
                        />
                      )}
                      {project.calendarEvent?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.calendarEvent.webViewLink}
                          text="Calendar"
                          Icon={EventIcon}
                        />
                      )}
                      {project.attendeesSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.attendeesSheet.webViewLink}
                          text="Attendees"
                          Icon={GroupIcon}
                        />
                      )}
                      {project.calendarEvent?.id && (
                        <PillButton
                          type="clickToCopy"
                          clickToCopyContent={project.calendarEvent?.id}
                          text="Cal Event ID"
                          Icon={CodeIcon}
                        />
                      )}
                      {project.projectFolder?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.projectFolder.webViewLink}
                          text="Project Folder"
                          Icon={FolderIcon}
                        />
                      )}
                      {project.plWorksheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.plWorksheet.webViewLink}
                          text="P&L Sheet"
                          Icon={DescriptionIcon}
                        />
                      )}
                      {project.customMessagingConfigSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={project.customMessagingConfigSheet.webViewLink}
                          text="Messaging Config (Custom)"
                          Icon={SettingsIcon}
                        />
                      )}
                      {!project.customMessagingConfigSheet?.webViewLink && (
                        <PillButton
                          type="externalUrl"
                          externalUrl={process.env.REACT_APP_DEFAULT_MESSAGING_CONFIG_SHEET_URL}
                          text="Messaging Config (Default)"
                          Icon={SettingsIcon}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={5} className="mb-1"></Grid>
          </div>

          <div className={classes.checklistsSection}>
            <div className={classes.infoLabelContainer}>
              <p className={classes.infoLabel}>Checklists</p>
            </div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <ChecklistsSection id={projectId} />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Event Type</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.displayLocationType}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Start Date</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedStartDate}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Start Time</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedStartTime}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>Methodology</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.projectMethodologyName}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>End Date</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedEndDate}</p>
                </div>

                <div className="mb-3">
                  <div className={classes.infoLabelContainer}>
                    <p className={classes.infoLabel}>End Time</p>
                  </div>

                  <p className={classes.infoTitleMD}>{project.formattedEndTime}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className={classes.infoLabelContainer}>
                  <p className={classes.infoLabel}>Service Type</p>
                </div>

                <p className={classes.infoTitleMD}>{project.serviceType}</p>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className={classes.jobsSection}>
          <div className="d-flex align-items-center justify-content-between">
            <div className={classes.infoLabelContainer}>
              <p className={classes.infoLabel}>Jobs</p>
            </div>

            {showAddJob && (
              <div
                className={classes.linkBtn}
                onClick={() => dispatch(openAddJobToDealProjectDialog(true, project.id))}
              >
                <AddCircleIcon fontSize="small" color="primary" />
                <p className={classes.linkBtnText}>Add Job</p>
              </div>
            )}
          </div>
          {isEmpty(project.jobs) && (
            <div className={cx(classes.noProjectsView, 'd-flex justify-content-center')}>
              <p className={classes.noProjectsText}>
                No jobs yet. Once jobs have been added you'll be able to track the status below.
              </p>
            </div>
          )}
          <Grid container spacing={5}>
            {map(
              orderBy(compact(project.jobs), [item => get(item, 'jobName').toLowerCase()]),
              job => {
                return (
                  <Grid item xs={6} key={job.jobId}>
                    <JobSeatCard jobId={job.jobId} projectId={project.id} isProjectActive={true} />
                  </Grid>
                );
              },
            )}
          </Grid>
        </div>
      </div>

      <StaffingChecklistDialog />
      <AddJobToDealProjectDialog />
      <AddProjectToDealDialog />
      <ManageContractorsDialog />
      {project.hasSyncedAttendees && (
        <>
          <SendAttendeeMessageDialog
            isOpen={attendeeMessageDialogIsOpen}
            handleClose={toggleAttendeeMessageDialog}
            projectId={project.id}
            projectAttendeeMessages={project.attendeeMessages || []}
          />
          <SendCohortMessageDialog
            isOpen={cohortMessageDialogIsOpen}
            handleClose={toggleCohortMessageDialog}
            projectId={project.id}
            projectAttendeeMessages={project.attendeeMessages || []}
          />
        </>
      )}
    </div>
  );
};

const ProjectStatusButton = props => {
  const { project } = props;
  const { status, id } = project;
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();

  const currentDate = new Date();
  const timeNowCT = formatInTimeZone(currentDate, 'America/Chicago', 'HH:mm:ss');
  const today = formatInTimeZone(currentDate, 'America/Chicago', 'yyyy-MM-dd');
  const nowDateTimeCT = `${today}T${timeNowCT}`;
  const projectEndDateTime = `${project.endDate}T${project.endTime}`;
  const projectHasEnded = nowDateTimeCT >= projectEndDateTime;

  const isDesign = project.serviceType === PROJECT_SERVICES.Design;
  const isProgram = project.serviceType === PROJECT_SERVICES.Program;

  const getButtonText = () => {
    switch (status) {
      case PROJECT_ERROR_STATUS_STATES.INITIALIZING:
        return 'Initialize';
      case PROJECT_STATUS_STATES.PENDING:
        return isDesign || isProgram ? 'Create Assets' : 'Schedule';
      case PROJECT_ERROR_STATUS_STATES.ADDING_CALENDAR:
        return 'Schedule';
      case PROJECT_STATUS_STATES.ADDING_CALENDAR:
      case PROJECT_STATUS_STATES.SCHEDULED:
      case PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS:
        return 'Create Assets';
      case PROJECT_STATUS_STATES.IN_PROGRESS:
        return 'Ready to Invoice';
      case PROJECT_STATUS_STATES.READY_TO_INVOICE:
        return 'Invoice';
      case PROJECT_ERROR_STATUS_STATES.READY_TO_INVOICE:
        return 'Ready to Invoice';
      case PROJECT_STATUS_STATES.INVOICING:
        return 'Paid';
      default:
        return '';
    }
  };

  const handleProjectStatus = () => {
    //if the Current Project state is in 'Error', display a confirmation..
    if (includes(Object.values(PROJECT_ERROR_STATUS_STATES), status)) {
      confirm({
        description:
          'You are attempting to re-run this step after an error. Please confirm the error has been corrected.',
      }).then(() => {
        //Update the Project to the equivalent Project Status state.
        const key = Object.keys(PROJECT_ERROR_STATUS_STATES).find(
          key => PROJECT_ERROR_STATUS_STATES[key] === status,
        ); //get the Key, e.g 'initializing-error' equals 'INITIALIZING'
        dispatch(updateProject(id, { status: PROJECT_STATUS_STATES[key] }));
      });
      return;
    }

    if (status === PROJECT_STATUS_STATES.PENDING && !isDesign && !isProgram) {
      dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.ADDING_CALENDAR }));
    } else if (
      (status === PROJECT_STATUS_STATES.PENDING && (isDesign || isProgram)) ||
      status === PROJECT_STATUS_STATES.SCHEDULED ||
      status === PROJECT_ERROR_STATUS_STATES.CREATING_ASSETS
    ) {
      if (!project.acceptedLeadRoles.includes(JOB_ROLES.Facilitator) || project.startDate < today) {
        dispatch(
          setInfoDialogContent({
            title: 'Cannot create project assets',
            content:
              'Project must have a start date in the future and also have an accepted Lead Facilitator',
          }),
        );
        dispatch(openInfoDialog(true));
      } else {
        if (!project.acceptedLeadRoles.includes(JOB_ROLES.Scribe)) {
          confirm({
            description: 'You are about to create assets without an accepted Lead Scribe',
          }).then(() => {
            dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CREATING_ASSETS }));
          });
        } else {
          dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CREATING_ASSETS }));
        }
      }
    } else if (status === PROJECT_STATUS_STATES.IN_PROGRESS) {
      confirm({
        description:
          "You are attempting manually change the project state to 'Ready to Invoice'. This will create a followup tasks for the project in HubSpot and notify ap@voltagecontrol.com.",
      })
        .then(() => {
          dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.READY_TO_INVOICE }));
        })
        .catch(e => {});
    } else if (status === PROJECT_STATUS_STATES.READY_TO_INVOICE) {
      dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.INVOICING }));
    } else if (status === PROJECT_STATUS_STATES.INVOICING) {
      dispatch(updateProject(id, { status: PROJECT_STATUS_STATES.CLOSED }));
    }
  };

  //hide the button if the project is in the below status
  //also prevent scheduling if the Project isn't Design, and Start/End Date not set yet
  if (
    (![PROJECT_SERVICES.Design, PROJECT_SERVICES.Program].includes(project.serviceType) &&
      (!project.startDate || !project.startTime || !project.endDate || !project.endTime)) ||
    includes(
      [
        PROJECT_STATUS_STATES.INITIALIZING,
        PROJECT_STATUS_STATES.CREATING_ASSETS,
        PROJECT_STATUS_STATES.CLOSED,
      ],
      status,
    ) ||
    (status === PROJECT_STATUS_STATES.IN_PROGRESS &&
      !(projectHasEnded && project.checklistsCompleted))
  ) {
    return <div className="flex-1"></div>;
  }

  return (
    <div className={classes.dealStatusButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        className="text-uppercase px-4 py-1 ml-auto"
        size="small"
        disabled={status === PROJECT_STATUS_STATES.ADDING_CALENDAR}
        onClick={handleProjectStatus}
      >
        {getButtonText()}
      </Button>
    </div>
  );
};

export default ProjectDetails;
