import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';
import { map } from 'lodash';
// library components
import { Tooltip, MenuItem, InputLabel, Box, Select, Input, Typography } from '@material-ui/core';
// styles
import './textInput.scss';

const useSelectStyles = makeStyles({
  select: {
    paddingLeft: 5,
  },
});

const useSelectSizeClasses = makeStyles(theme => ({
  mediumContainer: {
    minWidth: 250,
  },
  largeContainer: {
    width: '100%',
  },
  customSelect: {
    marginBottom: theme.spacing(2),
  },
}));

const useInputStyles = makeStyles(theme => ({
  root: {
    background: '#f2f2f2',
  },
}));

const useLabelStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const useStyles = makeStyles(theme => ({
  helperText: {
    fontSize: 12,
    color: theme.palette.text.hint,
    fontStyle: 'italic',
  },
}));

const CustomSelect = ({
  name,
  customOnChange,
  placeholder,
  required,
  label,
  selectOptions,
  size,
  defaultValue,
  disabled,
  parentFieldValue,
  helperText,
  customStyles = { container: undefined, label: undefined },
  afterChange, // onChange callback
}) => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const selectSizeClasses = useSelectSizeClasses();
  const labelsClasses = useLabelStyles();
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();

  const parentValues = map(parentFieldValue, value => ({
    value: value.id,
    name: value.value,
  }));

  const hasError = (error && touched) || (submitCount && error) ? true : false;

  const renderOptions = options => {
    return options.map((option, idx) => (
      <MenuItem value={option.value} key={`option-${idx}`}>
        {option.tooltip ? (
          <Tooltip title={option.tooltip} arrow placement="left" enterDelay={500}>
            <span className="w-100">{option.name}</span>
          </Tooltip>
        ) : (
          option.name
        )}
      </MenuItem>
    ));
  };

  /**
   * TODO: [TECH DEBT] we shouldn't have to do this.  The form default values should handle this, but
   * is likely an issue related to #270.  It only happens when dynamically loading the form fields
   */

  const defaultSelectValue = defaultValue ? defaultValue : '';

  const handleChange = (event, value) => {
    field.onChange(event, value.props.value);

    if (afterChange) afterChange(value.props.value);
  };

  return (
    <div
      className={cx(
        customStyles.container || selectSizeClasses.customSelect,
        size === 'medium' ? selectSizeClasses.mediumContainer : selectSizeClasses.largeContainer,
      )}
    >
      {label && (
        <Box className="mb-1">
          <InputLabel
            variant="standard"
            disableAnimation
            shrink={false}
            className={cx(labelsClasses.default, customStyles.label || {})}
            error={hasError}
            required={required}
            htmlFor={field.name}
          >
            {label}
          </InputLabel>
          {helperText && <Typography className={classes.helperText}> {helperText}</Typography>}
        </Box>
      )}

      <Select
        {...field}
        name={field.name}
        onBlur={field.onBlur}
        onChange={customOnChange ?? handleChange}
        classes={selectClasses}
        id={field.name}
        fullWidth
        placeholder={placeholder}
        value={field.value || defaultSelectValue}
        disabled={disabled}
        input={<Input classes={useInputStyles()} error={hasError} />}
      >
        {renderOptions(parentFieldValue ? [...selectOptions, ...parentValues] : selectOptions)}
      </Select>
      {hasError ? (
        <Box component="span" color="error.main" style={{ fontWeight: '400' }}>
          {error}
        </Box>
      ) : null}
    </div>
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  customOnChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  size: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  parentFieldValue: PropTypes.any,
  helperText: PropTypes.string,
  afterChange: PropTypes.func, // onChange callback
};

export default CustomSelect;
