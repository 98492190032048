import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
//import { Switch, Route, Redirect, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { setIdToken } from 'redux/actions/idTokenActions';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Footer from 'components/Footer/Footer.js';
import SidebarPro from 'components/Sidebar/SidebarPro.js';
import Hidden from '@material-ui/core/Hidden';
import CrLogo from 'components/CrLogo/CrLogo';
import Sessions from '../views/Admin/Sessions/Sessions';
import WhitelistInvite from '../views/Admin/WhitelistInvite/WhitelistInvite';
import FreeSubscriptionInvite from '../views/Admin/FreeSubscriptionInvite/FreeSubscriptionInvite';
import PremiumSubscriptionDialog from 'components/Dialogs/PremiumSubscriptionDialog';
import OverlaySpinner from 'components/OverlaySpinner';
import FacilitatorAccountSettingsDialog from 'components/Dialogs/FacilitatorAccountSettingsDialog';
import InfoDialog from 'components/Dialogs/InfoDialog';
import Staffing from '../views/Admin/Staffing/Staffing';
import ContractorDashboard from '../views/Admin/Staffing/ContractorDashboard';
import MuralAdminSessionActivation from 'views/Admin/Mural/MuralAdminSessionActivation';

import routes from 'routes.js';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import { localStorageGetObject } from 'utils/localStorageUtils';
import { impersonateUser } from 'utils/impersonateUser';

// actions
import {
  openPremiumSubscriptionDialog,
  openInfoDialog,
  closeInfoDialog,
} from 'redux/actions/facilitatorSessionActions';
import MuralAuthDialog from 'components/Dialogs/MuralAuthDialog';
import { openMuralAuthDialog } from 'redux/actions/facilitatorSessionActions';

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const impersonate = localStorageGetObject('impersonate');
  const { ...rest } = props;
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const {
    isOpenPremiumDialog,
    showAppOverlaySpinner,
    isOpenInfoDialog,
    infoDialogContent,
    isMuralAuthorized,
    isOpenMuralAuthDialog,
  } = useSelector(state => state.facilitatorSession);

  const user = useSelector(state => state.firebase.profile);

  const getDefaultRedirect = () => {
    if (user.staffingAdmin) return '/admin/staffing/deals';
    if (user.contractorId) return '/admin/contractor/';
  };

  // const idToken = useSelector(state => state.idToken);

  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  // const [fixedClasses, setFixedClasses] = useState("dropdown");

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    });

  // ref for main panel div
  const mainPanel = React.createRef();

  /**
   * If a facilitator manually tries to return to the facilitator view
   * without clicking the "Return to Facilitator View" button while impersonating
   * a participant we manually return them to the facilitator view or else they will
   * end up in an inconsistent state
   */
  useEffect(() => {
    if (impersonate) {
      impersonateUser.returnToFacilitator();
    }
  }, [impersonate]);

  useEffect(() => {}, []);

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  // useEffect(() => {
  //   console.log("set token effect");
  //   const tokenListener = firebase.auth().onIdTokenChanged(async firebaseAuthUser => {
  //     try {
  //       console.log('getting id token...');
  //       const userIdToken = await firebaseAuthUser.getIdToken();
  //       console.log('token refreshed!');
  //       dispatch(setIdToken(userIdToken));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });

  //   return () => tokenListener();
  // }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin' && !prop.sessionRoute) {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const closePremiumSubscribeModal = () => {
    dispatch(openPremiumSubscriptionDialog(false));
  };

  const closeInfoModal = () => {
    dispatch(closeInfoDialog());
  };

  // if (!idToken) return null;
  if (!getDefaultRedirect()) {
    return <Redirect to="/auth/user-error" replace={true} />;
  }

  return (
    <>
      {showAppOverlaySpinner && <OverlaySpinner fullscreen />}
      <div className={classes.wrapper}>
        <SidebarPro
          routes={routes}
          logo={<CrLogo />}
          // image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color="purple"
          bgColor="white"
          miniActive={miniActive}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <Hidden mdUp>
            <AdminNavbar
              sidebarMinimize={sidebarMinimize.bind(this)}
              miniActive={miniActive}
              brandText={getActiveRoute(routes)}
              handleDrawerToggle={handleDrawerToggle}
              {...rest}
            />
          </Hidden>

          <div className={classes.content} style={{ minHeight: '100vh', position: 'relative' }}>
            <div className={classes.container}>
              <Switch>
                {/* {getRoutes(routes)} */}
                <Route path="/admin/invite-u3a7oiMNxh95" component={WhitelistInvite} />
                <Route
                  path="/admin/free-sub-invite-u3a7oiMNxh95"
                  component={FreeSubscriptionInvite}
                />
                <Route path="/admin/sessions" component={Sessions} />
                <Route path="/admin/past-sessions" component={Sessions} />
                <Route path="/admin/shared-sessions" component={Sessions} />
                <Route path="/admin/staffing" component={Staffing} />
                <Route path="/admin/contractor" component={ContractorDashboard} />
                <Route
                  path="/admin/mural-session-activation"
                  component={MuralAdminSessionActivation}
                />
                <Redirect from="/admin" to={getDefaultRedirect()} />
              </Switch>
            </div>
          </div>
          <FacilitatorAccountSettingsDialog />
          <Footer fluid />
        </div>
        {isOpenPremiumDialog && (
          <PremiumSubscriptionDialog
            isOpen={isOpenPremiumDialog}
            handleClose={closePremiumSubscribeModal}
          />
        )}
        {/* General Purpose Info Dialog that can be loaded with content and opened via redux */}
        {isOpenInfoDialog && (
          <InfoDialog
            handleClose={closeInfoModal}
            isOpen={isOpenInfoDialog}
            title={infoDialogContent.title}
          >
            {infoDialogContent.content}
          </InfoDialog>
        )}
        {!isMuralAuthorized && (
          <MuralAuthDialog
            isOpen={isOpenMuralAuthDialog}
            handleClose={() => {
              dispatch(openMuralAuthDialog(false));
            }}
          />
        )}
      </div>
    </>
  );
}
