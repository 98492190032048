import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { includes } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

// core components
import { Card, Typography, CardContent } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import FlagIcon from '@material-ui/icons/Flag';
import DescriptionIcon from '@material-ui/icons/Description';
import TagIcon from '@material-ui/icons/LocalOffer';

//staffing components
import SelectMenu from 'components/Staffing/SelectMenu/SelectMenu';

//actions & selectors
import {
  updateContractorUser,
  openCreateContractorDialog,
  deleteContractorUser,
  openAddContractorToJobDialog,
} from 'redux/actions/staffingActions';
import { selectContractor } from 'redux/selectors/staffing/getContractor';

import './contractorcard.scss';
import TagChip from 'components/TagChip';

const useStyles = makeStyles({
  chipContainer: {
    margin: '10px 0px',
  },
  chipRoot: {
    margin: 3,
    borderRadius: 3,
  },
  chipLabel: {
    fontSize: 16,
  },
  rowView: {
    display: 'flex',
    marginBottom: 10,
  },
  noteIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  flagIcon: {
    width: 20,
    color: 'rgba(226, 53, 30, 1)',
  },
  tagIcon: {
    width: 20,
    color: 'rgba(158, 158, 158, 1)',
  },
  noteText: {
    fontSize: 14,
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.87)',
    marginLeft: 5,
  },
});

const ContractorCard = ({ id }) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const getContractor = selectContractor();
  const contractor = useSelector(state => getContractor(state, id));

  if (!contractor) {
    return null;
  }

  const {
    displayPhone,
    displayEmail,
    vcEmail,
    displayStatus,
    displayName,
    isActive,
    isAvailable,
    city,
    country,
    type,
    hourlyRate,
    notes,
    tags,
    flagComments,
  } = contractor;

  const renderActiveText = () => {
    return isActive ? 'Inactive' : 'Active';
  };

  const renderStatus = () => {
    return (
      <Typography
        className={cx('status', {
          'status-inactive': !isActive, //add the status-inactive class if Contractor isn't active
          'status-available': isAvailable,
        })}
      >
        {displayStatus}
      </Typography>
    );
  };

  const handleDetailNavigate = () => {
    history.push(`/admin/staffing/contractors/${id}`);
  };

  const handleClickHeader = event => {
    const className = event.target.className;
    if (includes(className, 'card-header')) {
      handleDetailNavigate();
    }
  };

  const markContractorAsInactive = () => {
    confirm({
      description: `You are about to mark ${displayName} as ${renderActiveText()}. Are you sure?`,
    }).then(() => {
      dispatch(updateContractorUser(id, { isActive: !isActive }));
    });
  };

  const deleteContractorProfile = () => {
    confirm({
      description: `You are about to delete ${displayName}'s profile. This action is permanent. Are you sure?.`,
    }).then(() => {
      dispatch(deleteContractorUser(id));
    });
  };

  // MENU CONTROL OPTIONS
  const menuOptions = [
    {
      title: 'Add to Job',
      onItemClick: () => {
        dispatch(openAddContractorToJobDialog(true, id));
      },
    },
    {
      title: 'Edit',
      onItemClick: () => {
        dispatch(openCreateContractorDialog(true, id));
      },
    },
    {
      title: `Mark ${renderActiveText()}`,
      onItemClick: markContractorAsInactive,
    },
    {
      title: 'Delete',
      onItemClick: deleteContractorProfile,
    },
  ];
  return (
    <Card
      className={cx('contractor-card', {
        'inactive-contractor-card': !isActive, //add the inactive class to the Card if contractor is inactive...
      })}
    >
      <CardContent>
        <div className="card-header" onClick={handleClickHeader}>
          <div onClick={handleDetailNavigate}>
            <Typography className="name">{displayName}</Typography>
            <Typography className="contactInfo">{displayEmail}</Typography>
            <Typography className="contactInfo">{vcEmail}</Typography>
            <Typography className="contactInfo">
              {city}, {country}, {displayPhone}
            </Typography>
          </div>

          <SelectMenu
            options={menuOptions}
            menuContainerClassName="contractor-card-menu"
            menuBtnClassName="contractor-card-menu-button"
          />
        </div>

        <div className="divider" />

        <div className="pointer mb-1" onClick={handleDetailNavigate}>
          <Typography className="info">
            {type} | ${hourlyRate}/hr
          </Typography>
          {renderStatus()}
        </div>

        {notes && (
          <div className={classes.rowView}>
            <DescriptionIcon className={classes.noteIcon} />
            <Typography className={classes.noteText}>{notes || 'None'}</Typography>
          </div>
        )}

        {flagComments && (
          <div className={classes.rowView}>
            <FlagIcon className={classes.flagIcon} />
            <Typography className={classes.noteText}>{flagComments || 'None'}</Typography>
          </div>
        )}
        <div>
          {tags && tags.length > 0 && (
            <div className={classes.rowView}>
              <TagIcon className={classes.tagIcon} />
              <div>
                {tags.map((tag, idx) => (
                  <TagChip key={idx} label={tag} />
                ))}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default ContractorCard;

ContractorCard.propTypes = {
  id: PropTypes.string.isRequired,
};
