import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { isEmpty } from 'react-redux-firebase';

//core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

//staffing components
import ContractorJobCard from 'components/Staffing/Contractors/ContractorJobCard/ContractorJobCard';

// M-UI makeStyles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    height: 40,
    minHeight: 'initial',
    borderBottom: '1px solid lightgrey',
  },
  tab: {
    minWidth: 80,
    fontSize: 13,
    height: 30,
  },
}));

const ContractorJobs = ({ jobs }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (_event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCardBtnClick = (id, action) => alert(`Id: ${id}, action: ${action}`);

  const displayedJobs = filter(jobs, { isActive: activeTab === 0 });

  return (
    <div className="flex-1">
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="contractor jobs tabs"
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab
            label="OPEN JOBS"
            {...a11yProps(0)}
            className={classes.tab}
            style={{ marginLeft: '25px' }}
            disableRipple
          />
          <Tab label="PAST JOBS" {...a11yProps(1)} className={classes.tab} disableRipple />
        </Tabs>
      </AppBar>

      <TabPanel value={activeTab} index={0}>
        {isEmpty(displayedJobs) ? (
          <Typography style={{ fontSize: 18 }} className="w-100 text-center my-4">
            No Jobs assigned yet
          </Typography>
        ) : null}

        {displayedJobs.map((job, idx) => (
          <ContractorJobCard key={idx.toString()} job={job} onClick={handleCardBtnClick} />
        ))}
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        {isEmpty(displayedJobs) ? (
          <Typography style={{ fontSize: 18 }} className="w-100 text-center my-4">
            No Jobs assigned yet
          </Typography>
        ) : null}

        {displayedJobs.map((job, idx) => (
          <ContractorJobCard key={idx.toString()} job={job} onClick={handleCardBtnClick} />
        ))}
      </TabPanel>
    </div>
  );
};

// TAB PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contractor-jobs-tabpanel-${index}`}
      aria-labelledby={`contractor-jobs-tab-${index}`}
      {...other}
    >
      {value === index && <div className="px-5 py-2">{children}</div>}
    </div>
  );
}

// TAB PANEL PROPS
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// A11Y_PROPS
function a11yProps(index) {
  return {
    id: `contractor-jobs-tab-${index}`,
    'aria-controls': `contractor-jobs-tabpanel-${index}`,
  };
}

export default ContractorJobs;
