import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

// core components
import { makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';

import PickerSelect from 'components/Staffing/PickerSelect/PickerSelect';

import { setDealsFilterPayload } from 'redux/actions/staffingActions';
import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';

import './projectsFilter.scss';

// useStyles
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    gap: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    marginRight: -5,
    flexGrow: 1,
  },
  input: {
    width: 143,
    backgroundColor: '#EEEEEE',
  },
  inputLabel: {
    paddingLeft: 10,
    zIndex: 2,
  },
}));

const ProjectsFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { projectsActiveStatusFilter, showClosedProjects } = useStaffingSelector();

  const handleSelect = (value, filterParam) => {
    dispatch(
      setDealsFilterPayload({
        [filterParam]: value,
      }),
    );
  };

  const handleShowClosedCheckbox = (event, value) => {
    dispatch(
      setDealsFilterPayload({
        showClosedProjects: value,
      }),
    );
  };

  return (
    <div className={cx(classes.main)}>
      <PickerSelect
        label=""
        classNames="deals-filter-select-input"
        options={[
          {
            value: 'active',
            name: 'Active',
          },
          { value: 'inactive', name: 'Inactive' },
        ]}
        defaultValue="active"
        onChange={value => {
          handleSelect(value, 'projectsActiveStatusFilter');
        }}
      />

      {/* Hide the Schedule Filter when above filter is inactive */}
      {projectsActiveStatusFilter !== 'inactive' && (
        <PickerSelect
          label=""
          classNames="deals-filter-select-input"
          options={[
            {
              value: 'scheduled',
              name: 'Has Schedule',
            },
            {
              value: 'not-scheduled',
              name: 'Not Scheduled',
            },
          ]}
          onChange={value => {
            handleSelect(value, 'projectsScheduledStatusFilter');
          }}
        />
      )}

      <FormControlLabel
        className="align-self-end ml-1 text-dark"
        control={
          <Checkbox
            checked={showClosedProjects}
            onChange={handleShowClosedCheckbox}
            name="showClosedProjects"
            color="primary"
          />
        }
        label="Show Closed"
      />
    </div>
  );
};

export default ProjectsFilter;
