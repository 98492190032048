import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import cx from 'classnames';

//core components
import { useConfirm } from 'material-ui-confirm';
import { Card, CardContent, makeStyles, Divider, Button, Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VideocamIcon from '@material-ui/icons/Videocam';
import LinkIcon from '@material-ui/icons/Link';
import EventIcon from '@material-ui/icons/Event';
import FolderIcon from '@material-ui/icons/Folder';
import { Typography } from '@material-ui/core';

//cr components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PillButton from 'components/PillButton';

//actions & selectors
import {
  openProjectRoleActionDialog,
  updateAssigneeInJob,
  sendContractReminder,
} from 'redux/actions/staffingActions';
import { selectSignedInContractor } from 'redux/selectors/staffing/getContractor';

import { getProjectStatusStyles } from 'utils/staffing/status';
import { PROJECT_STATUS_STATES } from 'constants/staffing';

import { includes, size, filter } from 'lodash';

import './openJobsCard.scss';

// const PROJECT_STATUS_STATES_WITH_ASSETS = [PROJECT_STATUS_STATES.IN_PROGRESS,
//   PROJECT_STATUS_STATES.READY_TO_INVOICE, PROJECT_STATUS_STATES.INVOICING, PROJECT_STATUS_STATES.CLOSED];

const PROJECT_STATUS_STATES_WITH_CALENDAR = [
  PROJECT_STATUS_STATES.SCHEDULED,
  PROJECT_STATUS_STATES.CREATING_ASSETS,
  PROJECT_STATUS_STATES.IN_PROGRESS,
  PROJECT_STATUS_STATES.READY_TO_INVOICE,
  PROJECT_STATUS_STATES.INVOICING,
  PROJECT_STATUS_STATES.CLOSED,
];

const PROJECT_STATUS_STATES_READY_FOR_INVOICE = [
  PROJECT_STATUS_STATES.INVOICING,
  PROJECT_STATUS_STATES.CLOSED,
];

// useStyles
const useStyles = makeStyles({
  openJobCard: {
    position: 'relative',
  },
  openJobCardDisabled: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 2,
    },
  },
  cardContentDisabled: {
    opacity: 0.5,
    filter: 'grayscale(100%)',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  cardHeaderTitle: {
    fontSize: '15px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    fontWeight: '500',
  },
  cardHeaderSubTitle: {
    fontWeight: '300',
    fontSize: '13px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
  },
  cardDescription: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    color: '#5E5E5E',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: 4,
    marginTop: 4,
  },
  controllerButton: {
    fontSize: 11,
    width: '105px',
    height: '34px',
    textTransform: 'uppercase',
    color: '#fff',
  },
});

/**
 * Contractor facing job card
 */
const OpenJobCard = ({ job, contractorId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const getContractor = selectSignedInContractor();
  const contractor = useSelector(state => getContractor(state));

  //const showFolder = includes(PROJECT_STATUS_STATES_WITH_ASSETS, job.projectStatus)
  const showCal = includes(PROJECT_STATUS_STATES_WITH_CALENDAR, job.projectStatus);
  const showInvoice = includes(PROJECT_STATUS_STATES_READY_FOR_INVOICE, job.projectStatus);

  const availableSeats =
    size(filter(job.assignees, m => includes(['accepted', 'signed'], m.status))) < job.seats;

  const staffType =
    contractor.staffType && contractor.staffType !== 'Employee' ? contractor.staffType : 'Employee';

  const openAcceptRejectDialog = (action, jobId, projectId) => {
    dispatch(openProjectRoleActionDialog(true, action, jobId, projectId));
  };

  const handleSendContract = () => {
    dispatch(
      sendContractReminder(job.contractorEmail, job.assigneeInfo.helloSignatureRequestId, true),
    );
  };

  // Render buttons
  const renderButtons = () => {
    if (!job.isActive) {
      return (
        <>
          {staffType !== 'Employee' &&
            showInvoice && ( //
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={cx(classes.controllerButton)}
                as="a"
                href={`mailto:ap@voltagecontrol.com?subject=Invoice For ${job.eventName}`}
              >
                Send Invoice
              </Button>
            )}
        </>
      );
    }

    switch (job.status) {
      case 'pending':
        if (availableSeats) {
          return (
            <>
              <Button
                className={cx(classes.controllerButton, 'bg-danger')}
                variant="contained"
                size="small"
                onClick={() => {
                  openAcceptRejectDialog('reject', job.jobId, job.projectId);
                }}
              >
                {job.isTentative ? 'No' : 'Reject'}
              </Button>
              <Button
                className={cx(classes.controllerButton, 'bg-success')}
                variant="contained"
                size="small"
                onClick={() => {
                  openAcceptRejectDialog('accept', job.jobId, job.projectId);
                }}
              >
                {job.isTentative ? 'Yes' : 'Accept'}
              </Button>
            </>
          );
        } else {
          return (
            <>
              <Button
                variant="outlined"
                size="small"
                color="default"
                disabled
                className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
              >
                Unavailable
              </Button>
            </>
          );
        }

      case 'rejected':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              disabled
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Declined
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cx(classes.controllerButton)}
              onClick={() => {
                confirm({
                  description:
                    'You are about to undo your rejection of this Job. Are you sure you want to continue?',
                }).then(() => {
                  dispatch(
                    updateAssigneeInJob(job.projectId, job.jobId, contractorId, {
                      status: 'pending',
                    }),
                  );
                });
              }}
            >
              Undo
            </Button>
          </>
        );
      case 'accepted':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              disabled
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Accepted
            </Button>
            {staffType !== 'Employee' && job.assigneeInfo.helloSignatureRequestId && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={cx(classes.controllerButton, 'bg-success')}
                onClick={handleSendContract}
              >
                Get Contract
              </Button>
            )}
          </>
        );
      case 'signed':
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              color="default"
              disabled
              className={cx(classes.controllerButton, 'bg-transparent text-secondary')}
            >
              Contracted
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderJobDescription = () => {
    if (job.status === 'rejected') {
      return job?.assigneeInfo?.notes ?? '';
    }
    return job.isTentative
      ? 'This project date is tentative. Will you hold the date on your calendar for this job?'
      : job.description;
  };

  return (
    <Card
      className={cx(
        classes.openJobCard,
        'openJobCard',
        !availableSeats && classes.openJobCardDisabled,
      )}
    >
      <CardContent className={!availableSeats && classes.cardContentDisabled}>
        <GridContainer className="w-100 m-0">
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <div className="openJobCard-about">
              <p className="openJobCard-about-title">{job.clientName}</p>
              <p className="openJobCard-about-event">{job.eventName}</p>

              <div className="d-flex align-items-center mb-1">
                {job.isTentative && (
                  <div className="openJobCard-about-tentative bg-success">
                    <p>Tentative</p>
                  </div>
                )}
                <p className="openJobCard-about-date mb-0">
                  {job.formattedProjectStartDate} - {job.formattedProjectEndDate}
                </p>
              </div>
              <div
                className={cx(
                  'mr-1',
                  'project-card-body-status',
                  getProjectStatusStyles(job.projectStatus).containerClassname,
                )}
                style={{ display: 'inline-flex' }}
              >
                <Typography className={cx('project-card-body-status-text white-space-no-wrap')}>
                  {getProjectStatusStyles(job.projectStatus).title}
                </Typography>
              </div>
              <div className="openJobCard-about-description">
                <p>{job.projectDescription}</p>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <div className="openJobCard-jobDetails">
              <p className="openJobCard-jobDetails-title">Job Details</p>

              <Card className={'openJobsDetailCard'}>
                <CardContent>
                  <div className={classes.cardHeader}>
                    <div>
                      <p className={cx(classes.cardHeaderTitle, 'mb-0')}>
                        {job.jobName}, {job.jobRole}
                      </p>
                      <p className={cx(classes.cardHeaderSubTitle, 'mb-0')}>
                        {job.seniority}{' '}
                        {contractor?.staffType === 'Contractor' ? job.displayFee : ''}
                      </p>
                    </div>
                    <div className={classes.buttonContainer}>{renderButtons()}</div>
                  </div>

                  <Divider className="my-1" />
                  <div>
                    <p className={classes.cardDescription}>{renderJobDescription()}</p>
                  </div>
                </CardContent>
              </Card>
              <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                {(job.status === 'accepted' || job.status === 'signed') && (
                  <>
                    {job.projectIsPublic !== null && (
                      <PillButton
                        type="plainText"
                        text={job.projectIsPublic ? 'Public' : 'Private'}
                        disabled
                      />
                    )}
                    {job.projectIsFree && <PillButton type="plainText" text="Free" disabled />}
                    {job.projectVenue && (
                      <PillButton type="plainText" text={job.projectVenue} Icon={LocationOnIcon} />
                    )}
                    {job.projectZoomLink && (
                      <PillButton
                        type="externalUrl"
                        externalUrl={job.projectZoomLink}
                        text="Zoom Link"
                        Icon={VideocamIcon}
                      />
                    )}
                    {job.status === 'signed' && job.projectProjectBrief?.webViewLink && (
                      <PillButton
                        type="externalUrl"
                        externalUrl={job.projectProjectBrief.webViewLink}
                        text="Project Brief"
                        Icon={LinkIcon}
                      />
                    )}
                    {job.status === 'signed' && job.projectChecklistDoc?.webViewLink && (
                      <PillButton
                        type="externalUrl"
                        externalUrl={job.projectChecklistDoc.webViewLink}
                        text="Checklist"
                        Icon={LinkIcon}
                      />
                    )}
                    {showCal && job.projectCalendarEvent?.webViewLink && (
                      <PillButton
                        type="externalUrl"
                        externalUrl={job.projectCalendarEvent.webViewLink}
                        text="Calendar"
                        Icon={EventIcon}
                      />
                    )}
                    {job.status === 'signed' && job.projectProjectFolder?.webViewLink && (
                      <PillButton
                        type="externalUrl"
                        externalUrl={job.projectProjectFolder.webViewLink}
                        text="Project Folder"
                        Icon={FolderIcon}
                      />
                    )}
                  </>
                )}
              </Box>
            </div>
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

OpenJobCard.propTypes = {
  job: PropTypes.object.isRequired,
  contractorId: PropTypes.string.isRequired,
};

export default OpenJobCard;
