import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// core components
import { makeStyles } from '@material-ui/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

//cr components
import PageContent from 'components/PageContent/PageContent';
import PageContentHeader from 'components/PageContent/PageContentHeader';
import PageContentBody from 'components/PageContent/PageContentBody';
import SchedulesChart from 'components/Staffing/Schedules/SchedulesChart';
import SchedulesFilter from 'components/Staffing/Schedules/SchedulesFilter';

//actions & selectors
import { selectSchedules } from 'redux/selectors/staffing/getSchedules';
import { changeSelectedMonth } from 'redux/actions/staffingActions';

const useStyles = makeStyles({
  pageHeader: {
    // paddingBottom: '0px !important',
  },
  gridItem: {
    padding: '0 10px !important',
  },
  dateControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dateControlText: {
    marginBottom: 0,
    marginRight: 15,
    marginLeft: 15,
    fontWeight: 500,
  },
  dateControlBtn: {
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none !important',
    backgroundColor: '#EEEEEE',
    fontSize: 14,
  },
  arrowIcon: {
    color: '#5E5E5E',
    fontSize: 'inherit',
  },
});

const ProjectsList = () => {
  const classes = useStyles();

  const getSchedules = selectSchedules();
  const dispatch = useDispatch();
  const { currentMonth, selectedScheduleMonthIndex, isLoaded } = useSelector(state =>
    getSchedules(state),
  );

  const handlePrevDateClick = () => {
    dispatch(changeSelectedMonth(selectedScheduleMonthIndex - 1));
  };
  const handleNextDateClick = () => {
    dispatch(changeSelectedMonth(selectedScheduleMonthIndex + 1));
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12}>
          <PageContent>
            <PageContentHeader className={classes.pageHeader}>
              <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={4} className={classes.dateControlWrapper}>
                  {currentMonth && (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.dateControlBtn}
                        onClick={handlePrevDateClick}
                      >
                        <ArrowBackIosIcon className={classes.arrowIcon} />
                        Prev
                      </Button>
                      <p className={classes.dateControlText}>
                        {currentMonth.start} - {currentMonth.end}
                      </p>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.dateControlBtn}
                        onClick={handleNextDateClick}
                      >
                        Next
                        <ArrowForwardIosIcon className={classes.arrowIcon} />
                      </Button>
                    </>
                  )}
                </GridItem>
                <GridItem xs={4}>
                  <Typography variant="h5" className="page-content-title">
                    Schedules
                  </Typography>
                </GridItem>
                <GridItem xs={4}></GridItem>
              </GridContainer>
            </PageContentHeader>
            <PageContentBody>
              <SchedulesFilter />
              <SchedulesChart />
            </PageContentBody>
          </PageContent>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ProjectsList;
