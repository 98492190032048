import moment from 'moment';
import { isLoaded } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import {
  map,
  filter,
  size,
  fill,
  toArray,
  orderBy,
  min,
  get,
  includes,
  values,
  compact,
  find,
  isEmpty,
} from 'lodash';
import { formatPrice } from 'utils/formatPrice';
import { getJobRolesMarkedAsLead, getAcceptedLeadRoles } from 'utils/staffing/projects';
import { PROJECT_LOCATION_TYPES_SELECT_OPTIONS, PROJECT_STATUS_STATES } from 'constants/staffing';

const projectsSelector = (state, id) => state.firestore.data.projects;
const projectSelector = (state, id) => state.firestore.data.projects?.[id];
const jobSelector = (state, id, jobId) => state.firestore.data.projects?.[id]?.jobs[jobId];
const contractorsSelector = (state, id) => state.firestore.data.contractors;
const projectMethodologiesSelector = state => state.firestore.data.projectMethodologies;
const dealsSelector = state => state.firestore.data.deals;
const staffingSelector = state => state.staffing;
const companiesSelector = state => state.firestore.data.companies;

/**
 * Selector for retrieving all Projects
 */
export const selectProjects = () => {
  return createSelector([projectsSelector, staffingSelector], (projects, staffing) => {
    if (!isLoaded(projects)) return { isLoaded: false };

    const filteredProjects = compact(
      map(projects, project => {
        return filterOutProjects(project, staffing);
      }),
    );

    return {
      projects: map(orderBy(filteredProjects, ['eventName'], ['desc']), project => {
        const isActive = checkProjectIsActive(project);
        const hasJobs = !isEmpty(project.jobs);

        return {
          ...project,
          isActive,
          hasJobs,
        };
      }),
      isLoaded: true,
    };
  });
};

/**
 * Selector for retrieving the Project info from the Redux Store
 */
export const selectProject = () => {
  return createSelector(
    [
      projectSelector,
      contractorsSelector,
      dealsSelector,
      projectMethodologiesSelector,
      companiesSelector,
    ],
    (project, contractors, deals, projectMethodologies, companies) => {
      if (!isLoaded(project) || !isLoaded(deals) || !isLoaded(projectMethodologies)) return null;

      const { startDate, endDate, jobs, startTime, endTime, locationType } = project;

      const formattedStartDate = startDate ? moment(startDate).format('MM/DD/YYYY') : 'Not Set';
      const formattedEndDate = endDate ? moment(endDate).format('MM/DD/YYYY') : 'Not Set';

      const formattedStartTime = startDate && startTime ? startTime : 'Not Set';
      const formattedEndTime = endDate && endTime ? endTime : 'Not Set';

      const isActive = checkProjectIsActive(project);

      let numberOfFilledJobs = 0;
      let projectTotalCost = 0;

      const deal = deals ? deals[project.dealId] : null;
      const projectMethodology = projectMethodologies
        ? projectMethodologies[project.projectMethodologyId]
        : null;

      //Get the display name for the Location Type
      const displayLocationType =
        find(PROJECT_LOCATION_TYPES_SELECT_OPTIONS, m => m.value === locationType)?.name || '';

      map(jobs, job => {
        //Get the Number of Jobs that all Seats have been accepted by Contractors
        const allSeatsAccepted =
          size(filter(values(job.assignees), m => includes(['accepted', 'signed'], m.status))) ===
          job.seats;
        if (allSeatsAccepted) {
          numberOfFilledJobs = numberOfFilledJobs + 1;
        }

        //Get the Project Total Cost by Multiplying the Job Hourly Rate of every Assignee by the Max Hours
        map(job.assignees, assignee => {
          const contractor = contractors[assignee.contractorId];
          if (contractor) {
            projectTotalCost =
              projectTotalCost +
              (job.isFixedFee
                ? job.fixedRate
                : min([Number(job.hourlyRate), Number(contractor.hourlyRate)]) * job.maxHours); //If Job uses fix fees, use that value, if not, use the hourlyRate * maxHours to calculate the Project Total Cost
          }
        });
      });

      const checklistsCompleted = project.checklists
        ? filter(project.checklists, chklst => !chklst.completed).length === 0
        : true;

      const projectCompany = deal ? companies[deal.companyId] : null;

      return {
        ...project,
        checklistsCompleted,
        jobs: orderBy(jobs, ['startDate'], ['asc']),
        jobRolesAlreadyMarkedAsLead: getJobRolesMarkedAsLead(jobs),
        acceptedLeadRoles: getAcceptedLeadRoles(jobs),
        numberOfFilledJobs,
        projectTotalCost,
        isActive,
        formattedStartDate,
        formattedEndDate,
        formattedStartTime,
        formattedEndTime,
        displayLocationType,
        formattedProjectContractValue: formatPrice(project.contractValue),
        dealName: deal?.dealName || 'Not set',
        dealContactName: deal?.contactName || 'Not set',
        dealContactEmail: deal?.contactEmail || 'Not set',
        isDealPublic: projectCompany?.companyName === 'Voltage Control',
        clientName: projectCompany?.companyName || 'Not set',
        projectMethodologyName: projectMethodology?.title || '',
      };
    },
  );
};

/**
 * Selector for retrieving the Job Info of a Project from the Redux Store
 */
export const selectJob = () => {
  return createSelector([jobSelector, contractorsSelector], (job, contractors) => {
    if (!job) return null;
    if (!isLoaded(contractors)) return null;

    //Get the Job Contractors Info from the Contractors store
    const jobContractors = [];
    job &&
      map(job.assignees, assignee => {
        const contractor = contractors[assignee?.contractorId ?? ''];
        if (contractor) {
          jobContractors.push({ ...contractor, ...assignee });
        }
      });

    const pendingInvitations = toArray(filter(jobContractors, { status: 'pending' }));
    const acceptedInvitations = toArray(
      filter(jobContractors, contractor => {
        return contractor.status === 'accepted' || contractor.status === 'signed';
      }),
    );
    const rejectedInvitations = toArray(filter(jobContractors, { status: 'rejected' }));

    // const unAssignedSeats = Number(job.seats) - size(acceptedInvitations) - size(rejectedInvitations);

    // const placeHolderSeats = fill(Array(unAssignedSeats), ""); //create an array of remaining seats

    const displayFee = job.isFixedFee
      ? `${formatPrice(job.fixedRate)}`
      : `${formatPrice(job.hourlyRate)}/hr (max ${job.maxHours} hours)`;

    return {
      ...job,
      displayFee,
      allContractors: jobContractors,
      // placeHolderSeats,
      pendingInvitations,
      acceptedInvitations,
      rejectedInvitations,
    };
  });
};

/**
 * Checks if the Start Date has passed to know whether the project is active
 * @param {Object} project
 * @returns
 */
export const checkProjectIsActive = project => {
  if (!project) {
    return false;
  }

  const yesterdayDate = moment().subtract(1, 'day').endOf('day').format();
  const status = moment(project.endDate).isAfter(yesterdayDate) ? 'active' : 'finished';

  const isActive = status === 'active';
  return isActive;
};

export const selectProjectMethodologies = () => {
  return createSelector([projectMethodologiesSelector], projectMethodologies => {
    if (!isLoaded(projectMethodologies)) return { projectMethodologies: [], isLoaded: false };

    return {
      projectMethodologies: map(
        orderBy(projectMethodologies, ['title'], ['asc']),
        projectMethodology => {
          return {
            name: projectMethodology.title,
            value: projectMethodology.id,
          };
        },
      ),
      isLoaded: true,
    };
  });
};

export const selectDealsDropdown = () => {
  return createSelector([dealsSelector, companiesSelector], (deals, companies) => {
    if (!isLoaded(deals)) return { deals: [], isLoaded: false };

    deals = filter(deals, deal => {
      return deal.status !== 'completed' && deal.status !== 'closed';
    });

    return {
      deals: map(orderBy(deals, [sort => get(sort, 'dealName').toLowerCase()]), deal => {
        const companyName = companies[deal.companyId]?.companyName;
        return {
          name: `${deal.dealName} (${companyName})`,
          value: deal.id,
        };
      }),
      isLoaded: true,
    };
  });
};

/**
 *
 * @param {*} staffing
 * @param {*} project
 * @returns
 */
export const filterOutProjects = (project, staffing) => {
  const { projectsActiveStatusFilter, projectsScheduledStatusFilter, showClosedProjects } =
    staffing;

  if (!project) {
    return null;
  }

  if (!showClosedProjects && project.status === PROJECT_STATUS_STATES.CLOSED) {
    return null;
  }

  if (projectsActiveStatusFilter === 'active' && !checkProjectIsActive(project)) {
    return null;
  }

  if (projectsActiveStatusFilter === 'inactive' && checkProjectIsActive(project)) {
    return null;
  }

  if (projectsScheduledStatusFilter === 'scheduled' && !project.calendarEvent) {
    return null;
  }

  if (projectsScheduledStatusFilter === 'not-scheduled' && project.calendarEvent) {
    return null;
  }

  return project;
};
